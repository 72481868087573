import apiHelper from "../helpers/api.helper";

export const advertiserUtils = {
    update: async (uuid, { name, description, websiteUrl, logo }) => {
        const formData = new FormData();

        if (name !== undefined) {
            formData.append("name", name);
        }

        if (description !== undefined) {
            formData.append("description", description);
        }

        if (websiteUrl !== undefined) {
            formData.append("websiteUrl", websiteUrl);
        }

        if (logo !== undefined) {
            formData.append("logo", logo);
        }

        return await apiHelper.doApiCall("PUT", `/advertisers/${uuid}`, {
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    create: async ({
        name,
        description,
        websiteUrl,
        logo,
        email,
        firstname,
        lastname,
        phone,
        termsAccepted,
    }) => {
        const formData = new FormData();

        if (name !== undefined) {
            formData.append("name", name);
        }

        if (description !== undefined) {
            formData.append("description", description);
        }

        if (websiteUrl !== undefined) {
            formData.append("websiteUrl", websiteUrl);
        }

        if (logo !== undefined) {
            formData.append("logo", logo);
        }

        if (email !== undefined) {
            formData.append("email", email);
        }

        if (firstname !== undefined) {
            formData.append("firstname", firstname);
        }

        if (lastname !== undefined) {
            formData.append("lastname", lastname);
        }

        if (phone !== undefined) {
            formData.append("phone", phone);
        }

        if (termsAccepted !== undefined) {
            formData.append("termsAccepted", termsAccepted);
        }

        return await apiHelper.doApiCall("POST", `/advertisers`, {
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    getActive: async () => {
        return await apiHelper.doApiCall("GET", `/advertisers/active`);
    },
};
