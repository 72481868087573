import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { Textbox } from "../Textbox";
import { Textarea } from "../Textarea";
import { Button } from "../Button";
import { ImageInput } from "../ImageInput";
import { advertiserUtils } from "../../utils/advertiserUtils";
import { useTranslation } from "react-i18next";
import { SubPages, SubPage } from "../SubPages";
import { Label } from "../Label";

export const BecomeAdvertiserForm = () => {
    const { t } = useTranslation();

    const [userContext, dispatch] = useContext(UserContext);

    const [companyName, setCompanyName] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState(userContext?.user?.email || "");
    const [phone, setPhone] = useState("");
    const [description, setDescription] = useState("");
    const [logo, setLogo] = useState(null);
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    useEffect(() => {
        setCompanyName("");
        setDescription("");
        setWebsiteUrl("");
        setLogo(null);
        setEmail(userContext?.user?.email || "");
        setFirstname("");
        setLastname("");
        setPhone("");
        setTermsAccepted(false);
        setCurrentPage(0);
    }, [userContext?.user]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const updateResponse = await advertiserUtils.create({
                name: companyName,
                description,
                websiteUrl,
                logo: logo?.file,
                email,
                firstname,
                lastname,
                phone,
                termsAccepted,
            });

            if (updateResponse.status === 200) {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        ...userContext?.user,
                        advertiser: updateResponse.data.data,
                    },
                });
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    const validate = () => {
        if (
            !companyName ||
            !termsAccepted ||
            !firstname ||
            !lastname ||
            !email ||
            !phone
        ) {
            return false;
        }

        if (
            companyName === userContext?.user?.advertiser?.name &&
            description === userContext?.user?.advertiser?.description &&
            websiteUrl === userContext?.user?.advertiser?.websiteUrl &&
            logo?.file === undefined
        ) {
            return false;
        }

        return true;
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <SubPages currentPage={currentPage}>
                    <SubPage>
                        <div className="grid grid-cols-1 sm:grid-cols-12 gap-4">
                            <div className="sm:col-span-4">
                                <p
                                    className="text-secondary"
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            "text.become_an_advertiser_1"
                                        ),
                                    }}
                                />
                            </div>
                            <div className="sm:col-span-8">
                                <div className="grid grid-cols-1 xs:grid-cols-12 gap-4 mb-4">
                                    <div className="xs:col-span-4">
                                        <ImageInput
                                            label={t("label.logo")}
                                            value={logo}
                                            onChange={(v) => setLogo(v)}
                                        />
                                    </div>
                                    <div className="xs:col-span-8">
                                        <Textbox
                                            label={t("label.company_name")}
                                            placeholder={t(
                                                "label.company_name"
                                            )}
                                            value={companyName}
                                            onChange={(e) =>
                                                setCompanyName(e.target.value)
                                            }
                                            maxLength={32}
                                            className="mb-4"
                                            required
                                        />
                                        <Textbox
                                            label={t("label.website_url")}
                                            type="url"
                                            placeholder={t("label.website_url")}
                                            value={websiteUrl}
                                            onChange={(e) =>
                                                setWebsiteUrl(e.target.value)
                                            }
                                            maxLength={32}
                                        />
                                    </div>
                                </div>
                                <Textarea
                                    label={t("label.description")}
                                    placeholder={t("label.description")}
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                    maxLength={240}
                                />
                            </div>
                            <div className="sm:col-span-12 text-right">
                                <Button
                                    variant="primary"
                                    disabled={!companyName}
                                    onClick={() => setCurrentPage(1)}
                                >
                                    {t("button.next")}
                                </Button>
                            </div>
                        </div>
                    </SubPage>
                    <SubPage>
                        <div className="grid grid-cols-1 sm:grid-cols-12 gap-4">
                            <div className="sm:col-span-4">
                                <p className="text-secondary">
                                    {t("text.become_an_advertiser_2")}
                                </p>
                            </div>
                            <div className="sm:col-span-8">
                                <div className="grid xs:grid-cols-2 gap-4 mb-4">
                                    <Textbox
                                        label={t("label.firstname")}
                                        placeholder={t("label.firstname")}
                                        value={firstname}
                                        onChange={(e) =>
                                            setFirstname(e.target.value)
                                        }
                                        maxLength={32}
                                        required
                                    />
                                    <Textbox
                                        label={t("label.lastname")}
                                        placeholder={t("label.lastname")}
                                        value={lastname}
                                        onChange={(e) =>
                                            setLastname(e.target.value)
                                        }
                                        maxLength={32}
                                        required
                                    />
                                </div>
                                <Textbox
                                    label={t("label.email")}
                                    type="email"
                                    placeholder={t("label.email")}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    maxLength={255}
                                    className="mb-4"
                                    required
                                />
                                <Textbox
                                    label={t("label.phone")}
                                    type="phone"
                                    placeholder={t("label.phone")}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    maxLength={32}
                                    className="mb-4"
                                    required
                                />
                                <div className="mb-2">
                                    <Label
                                        className="inline-block"
                                        required={!termsAccepted}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={termsAccepted}
                                            onChange={() =>
                                                setTermsAccepted(!termsAccepted)
                                            }
                                            className="mr-2"
                                        />
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t("text.accept_terms"),
                                            }}
                                        />
                                    </Label>
                                </div>
                            </div>
                            <div className="sm:col-span-12 flex justify-end">
                                <Button
                                    variant="secondary"
                                    className="mr-4"
                                    onClick={() => setCurrentPage(0)}
                                >
                                    {t("button.back")}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={!validate()}
                                    isLoading={isLoading}
                                >
                                    {t("button.create_account")}
                                </Button>
                            </div>
                        </div>
                    </SubPage>
                </SubPages>
            </form>
        </div>
    );
};
