import classNames from "classnames";
import { useEffect, useRef } from "react";

export const SubPages = ({
    currentPage,
    children,
    wrapperClassName,
    className,
}) => {
    const topRef = useRef();

    useEffect(() => {
        if (topRef.current && window.scrollY > topRef.current.offsetTop) {
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentPage]);

    if (children?.length < 2) {
        console.error(
            "Children not an array, please provide multiple SubPages"
        );

        return null;
    }

    const renderChildren = children.map((child) => {
        return (
            <div
                className={classNames(
                    "w-full inline-block align-top",
                    child.props.className
                )}
            >
                {child}
            </div>
        );
    });

    return (
        <div className={classNames("overflow-hidden", wrapperClassName)}>
            <div ref={topRef} />
            <div
                className={classNames(
                    `w-[${
                        children.length * 100
                    }%] whitespace-nowrap transition-all`,
                    className
                )}
                style={{
                    transform: `translateX(-${currentPage * 100}%)`,
                }}
            >
                {renderChildren}
            </div>
        </div>
    );
};

export const SubPage = ({ children, className }) => {
    return (
        <div className={classNames("whitespace-normal", className)}>
            {children}
        </div>
    );
};
