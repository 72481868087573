import apiHelper from "../helpers/api.helper";

export const raffleUtils = {
    getRaffle: async (uuid) => {
        return await apiHelper.doApiCall("GET", `/raffles/${uuid}`);
    },
    delete: async (uuid) => {
        return await apiHelper.doApiCall("DELETE", `/raffles/${uuid}`);
    },
    search: async ({ filters }) => {
        return await apiHelper.doApiCall("GET", `/raffles`, {
            params: {
                filters: JSON.stringify(filters || []),
            },
        });
    },
    claim: async (uuid) => {
        return await apiHelper.doApiCall("POST", `/raffles/${uuid}/claim`);
    },
    pay: async (uuid, { referenceId }) => {
        return await apiHelper.doApiCall("POST", `/raffles/${uuid}/pay`, {
            data: {
                referenceId,
            },
        });
    },
    watch: async (uuid) => {
        return await apiHelper.doApiCall("GET", `/raffles/${uuid}/watch`);
    },
    claimTicket: async (uuid, { adKey, timestamps }) => {
        return await apiHelper.doApiCall(
            "POST",
            `/raffles/${uuid}/claimTicket`,
            {
                data: { adKey, timestamps },
            }
        );
    },
    claimWebsiteWatch: async (uuid, { adKey, timestamps }) => {
        return await apiHelper.doApiCall(
            "POST",
            `/raffles/${uuid}/claimWebsiteWatch`,
            {
                data: { adKey, timestamps },
            }
        );
    },
};
