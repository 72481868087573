import { DE_DE } from "./translations/de_de";
import { EN_GB } from "./translations/en_gb";

export const Translations = {
    en_gb: {
        translation: EN_GB,
    },
    de_de: {
        translation: DE_DE,
    },
    // tr_tr: {
    //     translation: TR_TR,
    // },
};
