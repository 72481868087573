import { useRef } from "react";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";

export const ImageInput = ({ value, onChange, className, label }) => {
    const { t } = useTranslation();

    const inputRef = useRef();

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleChange = (e) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const fr = new FileReader();

        fr.readAsDataURL(file);

        fr.onload = ({ target }) => {
            onChange({ blob: target.result, file });
        };
    };

    return (
        <div className={className}>
            {!!label && (
                <label className="block mb-1 text-primary text-opacity-90 font-semibold">
                    {label}
                </label>
            )}
            {!!value?.blob && (
                <div>
                    <div className="relative rounded-lg overflow-hidden mb-3">
                        <img src={value?.blob} className="w-full" />
                        <div
                            className="absolute w-full h-full left-0 top-0 bg-black/40 cursor-pointer opacity-0 hover:opacity-100 flex items-center justify-center"
                            onClick={handleClick}
                        >
                            <p>{t("button.choose_image")}</p>
                        </div>
                    </div>
                    <Button
                        variant="secondary"
                        className="w-full"
                        size="small"
                        onClick={() => onChange({ blob: null, file: null })}
                    >
                        {t("button.remove")}
                    </Button>
                </div>
            )}
            {!value?.blob && (
                <Button
                    variant="secondary"
                    className="w-full h-[124px]"
                    onClick={handleClick}
                >
                    {t("button.choose_image")}
                </Button>
            )}
            <input
                type="file"
                accept="image/png, image/jpeg, image/svg+xml"
                ref={inputRef}
                onChange={handleChange}
                hidden
            />
        </div>
    );
};
