import { useContext, useEffect, useState } from "react";
import { Page } from "../Page";
import { Card, Field, FieldContainer } from "../Card";
import { UserContext } from "../context/UserContext";
import { CWFATab } from "../tabs/CWFATab";
import { Tabs } from "../Tabs";
import { Textbox } from "../Textbox";
import { Message } from "../Message";
import { transformToLocaleDateString } from "../../helpers/date.helper";
import { PriceFormatter } from "../../helpers/number.helper";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { ChangePasswordTab } from "../tabs/ChangePasswordTab";
import { InterestsTab } from "../tabs/InterestsTab";
import { Link, useNavigate } from "react-router-dom";
import { LogoutDialog } from "../dialogs/LogoutDialog";
import { userUtils } from "../../utils/userUtils";
import { AdvertisementsNavigator } from "../navigators/AdvertisementsNavigator";
import { ClaimedRafflesTab } from "../tabs/ClaimedRafflesTab";
import { LanguageTab } from "../tabs/LanguageTab";
import {
    faArrowRightFromBracket,
    faCheckDouble,
    faHeart,
    faLanguage,
    faLock,
    faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import { campaignUtils } from "../../utils/campaignUtils";

export const AdvertisementsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [userContext, dispatch] = useContext(UserContext);

    const [isLoadingCreate, setIsLoadingCreate] = useState(false);

    useEffect(() => {
        if (!userContext?.user?.advertiser?.uuid) {
            return;
        }
    }, [userContext?.user]);

    const handleCreateCampaign = async () => {
        setIsLoadingCreate(true);

        try {
            const createResponse = await campaignUtils.create({});

            if (createResponse.status === 200) {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        ...userContext.user,
                        advertiser: {
                            ...userContext.user?.advertiser,
                            campaignCount:
                                (userContext.user?.advertiser?.campaignCount ||
                                    0) + 1,
                        },
                    },
                });

                navigate(
                    `/play/account/campaigns/${createResponse.data.data.uuid}`
                );
            }

            setIsLoadingCreate(false);
        } catch (err) {
            console.error(err);
            setIsLoadingCreate(false);
        }
    };

    return (
        <Page
            title={t("title.advertisements")}
            subtitle={t("subtitle.advertisements")}
            rightContent={
                <Button
                    onClick={handleCreateCampaign}
                    disabled={userContext?.user?.advertiser?.campaignCount >= 5}
                    isLoading={isLoadingCreate}
                >
                    {t("button.new_campaign")}
                </Button>
            }
        >
            <AdvertisementsNavigator className="mb-8" />
        </Page>
    );
};
