import { Page } from "../Page";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { raffleUtils } from "../../utils/raffleUtils";
import { RaffleCard } from "../cards/RaffleCard";
import { raffleUserUtils } from "../../utils/raffleUserUtils";
import { useNavigate } from "react-router";
import { WatchAdDialog } from "../dialogs/WatchAdDialog";
import { Loader } from "../Loader";
import { RaffleList } from "../lists/RaffleList";
import { Message } from "../Message";
import { useTranslation } from "react-i18next";
import { randomIntFromInterval } from "../RaffleSpinner";
import { VisitWebsiteDialog } from "../dialogs/VisitWebsiteDialog";
import { Advertisers } from "../Advertisers";

export const PlayPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [userContext, dispatch] = useContext(UserContext);

    const [raffles, setRaffles] = useState([]);
    const [myRaffles, setMyRaffles] = useState([]);
    const [wonRaffles, setWonRaffles] = useState([]);
    const [isLoadingMyRaffles, setIsLoadingMyRaffles] = useState(true);
    const [isLoadingRaffles, setIsLoadingRaffles] = useState(true);
    const [isLoadingWonRaffles, setIsLoadingWonRaffles] = useState(true);
    const [welcomeTranslationKey, setWelcomeTranslationKey] = useState(
        "text.welcome_back_1"
    );
    const [advertisers, setAdvertisers] = useState(false);

    const [currentAd, setCurrentAd] = useState(false);
    const [isWebsiteDialogOpen, setIsWebsiteDialogOpen] = useState(false);

    useEffect(() => {
        if (!!userContext?.user?.uuid && randomIntFromInterval(1, 1000) === 1) {
            setWelcomeTranslationKey("text.welcome_back_2");
        }
    }, [userContext?.user]);

    const fetchRaffles = async () => {
        setIsLoadingRaffles(true);

        const rafflesResponse = await raffleUtils.search({
            filters: [
                {
                    field: "raffle.endAt",
                    operator: ">",
                    value: new Date(),
                },
                {
                    field: "raffle.uuidWinner",
                    operator: "=",
                    value: null,
                },
            ],
        });

        if (rafflesResponse.status === 200) {
            setRaffles(rafflesResponse.data.data);
        }

        setIsLoadingRaffles(false);
    };

    const fetchMyRaffles = async () => {
        setIsLoadingMyRaffles(true);

        const myRafflesResponse = await raffleUserUtils.search({
            filters: [
                {
                    field: "raffle_user.uuidUser",
                    operator: "=",
                    value: userContext.user.uuid,
                },
                {
                    field: "raffle.endAt",
                    operator: ">",
                    value: new Date(),
                },
            ],
            extend: ["*"],
        });

        if (myRafflesResponse.status === 200) {
            setMyRaffles(myRafflesResponse.data.data);
        }

        setIsLoadingMyRaffles(false);
    };

    const fetchWonRaffles = async () => {
        setIsLoadingWonRaffles(true);

        const wonRafflesResponse = await raffleUserUtils.search({
            filters: [
                {
                    field: "raffle.uuidWinner",
                    operator: "=",
                    value: userContext.user.uuid,
                },
                {
                    field: "raffle.claimedAt",
                    operator: "=",
                    value: null,
                },
            ],
            extend: ["*"],
        });

        if (wonRafflesResponse.status === 200) {
            setWonRaffles(wonRafflesResponse.data.data);
        }

        setIsLoadingWonRaffles(false);
    };

    useEffect(() => {
        if (userContext?.user?.uuid) {
            fetchMyRaffles();
            fetchRaffles();
            fetchWonRaffles();
        }
    }, [userContext?.user?.uuid]);

    const filteredRaffles = (raffles || []).filter(
        (raffle) =>
            !myRaffles.length ||
            !myRaffles.find((myRaffle) => myRaffle.raffle.uuid === raffle.uuid)
    );

    const handleClaim = (data) => {
        if (!!data && !myRaffles.find((v) => v?.uuid === data?.uuid)) {
            setRaffles((raffles || []).filter((v) => v?.uuid !== data?.uuid));
            setMyRaffles([...myRaffles, data]);
        } else {
            setMyRaffles(
                (myRaffles || []).map((v) => {
                    if (v?.uuid === data?.uuid) {
                        return data;
                    }

                    return v;
                })
            );
        }

        if (!!currentAd?.campaign?.websiteUrl) {
            setIsWebsiteDialogOpen(true);

            return;
        }

        setCurrentAd(null);
    };

    const handleClaimWebsiteWatch = (data) => {
        if (!!data && !myRaffles.find((v) => v?.uuid === data?.uuid)) {
            setRaffles((raffles || []).filter((v) => v?.uuid !== data?.uuid));
            setMyRaffles([...myRaffles, data]);
        } else {
            setMyRaffles(
                (myRaffles || []).map((v) => {
                    if (v?.uuid === data?.uuid) {
                        return data;
                    }

                    return v;
                })
            );
        }

        setCurrentAd(null);
        setIsWebsiteDialogOpen(false);
    };

    const isLoading =
        isLoadingRaffles || isLoadingMyRaffles || isLoadingWonRaffles;

    return (
        <Page>
            {userContext?.isLoading ? (
                <div>
                    <div className="hidden sm:block">
                        <Loader duration="5.4s" className="h-8 mb-4 w-1/2" />
                    </div>
                    <div className="block sm:hidden">
                        <Loader duration="5.6s" className="h-8 mb-2" />
                        <Loader duration="5.8s" className="h-8 mb-4 w-2/3" />
                    </div>
                </div>
            ) : (
                <h2
                    className="text-2xl lg:text-3xl font-black mb-4"
                    dangerouslySetInnerHTML={{
                        __html: t(welcomeTranslationKey, {
                            value: userContext?.user?.username,
                        }),
                    }}
                />
            )}
            <Advertisers
                className="mb-8"
                onDataReceive={(v) => setAdvertisers(v)}
            />
            {isLoading && (
                <div className="space-y-8 mb-8">
                    <div className="space-y-4">
                        <Loader duration="6s" className="!h-6 w-3/4" />
                        <Loader duration="6.2s" className="!h-20" />
                        <Loader duration="6.4s" className="!h-20" />
                    </div>
                    <div className="space-y-4">
                        <Loader duration="6.6s" className="!h-6 w-3/4" />
                        <Loader duration="6.8s" className="!h-20" />
                        <Loader duration="7s" className="!h-20" />
                    </div>
                    <div className="space-y-4">
                        <Loader duration="7.2s" className="!h-6 w-3/4" />
                        <Loader duration="7.4s" className="!h-20" />
                        <Loader duration="7.6s" className="!h-20" />
                    </div>
                </div>
            )}
            {!isLoading && wonRaffles.length > 0 && (
                <div className="mb-8">
                    <h3 className="text-lg mb-4 text-gradient-primary font-black">
                        {t("title.win_congratulations")}
                    </h3>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                        {(wonRaffles || []).map((raffleUser) => {
                            return (
                                <RaffleCard
                                    key={`raffle-user-${raffleUser.uuid}`}
                                    raffleUser={raffleUser}
                                    raffle={raffleUser.raffle}
                                    onClick={() =>
                                        navigate(
                                            `/play/raffle/${raffleUser.raffle.uuid}`
                                        )
                                    }
                                    isWon
                                />
                            );
                        })}
                    </div>
                </div>
            )}
            {!isLoading && myRaffles.length > 0 && (
                <div>
                    <h3 className="text-lg mb-4 text-secondary">
                        {t("title.participating_raffles")}
                    </h3>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-8">
                        {(myRaffles || [])
                            .sort(sortMyRaffles)
                            .map((raffleUser) => {
                                return (
                                    <RaffleCard
                                        key={`raffle-user-${raffleUser.uuid}`}
                                        raffleUser={raffleUser}
                                        raffle={raffleUser.raffle}
                                        onClick={() =>
                                            navigate(
                                                `/play/raffle/${raffleUser.raffle.uuid}`
                                            )
                                        }
                                        onDataReceive={(v) => setCurrentAd(v)}
                                        isWatchDisabled={!advertisers.length}
                                    />
                                );
                            })}
                    </div>
                </div>
            )}
            {!isLoading && filteredRaffles.length > 0 ? (
                <div>
                    <h3 className="text-lg mb-4 text-secondary">
                        {t("title.more_raffles")}
                    </h3>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                        {(filteredRaffles || []).map((raffle) => {
                            return (
                                <RaffleCard
                                    key={`raffle-${raffle.uuid}`}
                                    raffle={raffle}
                                    onClick={() =>
                                        navigate(`/play/raffle/${raffle.uuid}`)
                                    }
                                    onDataReceive={(v) => setCurrentAd(v)}
                                    isWatchDisabled={!advertisers.length}
                                />
                            );
                        })}
                    </div>
                </div>
            ) : (
                <Message>{t("text.no_raffles_available")}</Message>
            )}
            <WatchAdDialog
                open={currentAd}
                ad={currentAd}
                onCancel={() => setCurrentAd(null)}
                onClaim={handleClaim}
            />
            <VisitWebsiteDialog
                open={isWebsiteDialogOpen}
                ad={currentAd}
                onCancel={() => {
                    setCurrentAd(null);
                    setIsWebsiteDialogOpen(false);
                }}
                onClaim={handleClaimWebsiteWatch}
            />
        </Page>
    );
};

const sortMyRaffles = (a, b) => {
    // First, check if either of the raffles is yearly
    if (
        a.raffle.repetition === "monthly" &&
        b.raffle.repetition !== "monthly"
    ) {
        return 1;
    } else if (
        a.raffle.repetition !== "monthly" &&
        b.raffle.repetition === "monthly"
    ) {
        return -1;
    } else if (
        a.raffle.repetition === "monthly" &&
        b.raffle.repetition === "monthly"
    ) {
        return 0;
    }

    // At this point, both raffles are not yearly
    // Handle cases where lastWatchedAt is missing
    if (!a.lastWatchedAt && !b.lastWatchedAt) {
        return 0;
    } else if (!a.lastWatchedAt) {
        return -1;
    } else if (!b.lastWatchedAt) {
        return 1;
    }

    // Both lastWatchedAt values are present, so we can safely compare them
    return new Date(a.lastWatchedAt) - new Date(b.lastWatchedAt);
};
