import { Tab } from "../Tabs";
import { RaffleList } from "../lists/RaffleList";

export const ClaimedRafflesTab = ({ ...props }) => {
    return (
        <Tab {...props}>
            <RaffleList onlyUnclaimedWins />
        </Tab>
    );
};
