import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Tab } from "../Tabs";
import { userUtils } from "../../utils/userUtils";
import { InterestSelect } from "../selects/InterestSelect";

export const InterestsTab = ({ onUpdate, ...props }) => {
    const { t } = useTranslation();

    const [userContext] = useContext(UserContext);

    const [interests, setInterests] = useState(
        userContext?.user?.interests || []
    );
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeInterestsSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true);

            const updateResponse = await userUtils.update(
                userContext?.user?.uuid,
                { interests }
            );

            if (updateResponse.status === 200) {
                if (onUpdate) {
                    onUpdate(updateResponse.data.data);
                }
            }

            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    };

    const validate = () => {
        if (interests.length < 3 || interests.length > 5) {
            return false;
        }

        return true;
    };

    return (
        <Tab {...props}>
            <form onSubmit={handleChangeInterestsSubmit}>
                <InterestSelect
                    value={interests}
                    onChange={(v) => setInterests(v)}
                    className="mb-8"
                    label={null}
                    error={
                        (interests.length < 3 || interests.length > 5) &&
                        t("validate.user_interests")
                    }
                />
                <div className="text-right">
                    <Button
                        type="submit"
                        variant="primary"
                        isLoading={isLoading}
                        disabled={!validate()}
                    >
                        {t("button.save")}
                    </Button>
                </div>
            </form>
        </Tab>
    );
};
