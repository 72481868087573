import classNames from "classnames";
import { Label } from "./Label";

const addSpace = (str, count) => {
    return str.replace(new RegExp(`(.{${count}})`, "g"), "$1 ");
};

export const Textbox = ({
    value,
    onChange,
    label,
    description,
    placeholder,
    className,
    type = "text",
    size = "medium",
    error,
    disabled,
    charSpaceCount,
    uppercase,
    required,
    button,
    ...props
}) => {
    // Function to add space after a certain number of characters
    const addSpace = (str, count) => {
        return str.replace(new RegExp(`(.{${count}})`, "g"), "$1 ").trim();
    };

    // Function to handle text change with spacing
    const handleChange = (event) => {
        if (charSpaceCount) {
            const inputValue = event.target.value.replaceAll(" ", "");
            const spacedValue = charSpaceCount
                ? addSpace(inputValue, charSpaceCount)
                : inputValue;

            event.target.value = spacedValue;
        }

        if (uppercase) {
            event.target.value = event.target.value.toUpperCase();
        }

        onChange(event);
    };

    return (
        <div className={className}>
            {!!label && <Label required={required && !value}>{label}</Label>}
            <div className="flex">
                <input
                    className={classNames(
                        "bg-white/5 rounded-md w-full outline-none text-white grow",
                        {
                            "border-red-500": !!error,
                            "p-2": size === "medium",
                            "p-4 text-lg": size === "large",
                        }
                    )}
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    type={type}
                    disabled={disabled}
                    {...props}
                />
                {!!button && <div className="ml-2">{button}</div>}
            </div>
            {!!error && (
                <span className="text-red-500 mt-1 block text-sm">{error}</span>
            )}
            {!!description && (
                <p className="text-secondary mt-2">{description}</p>
            )}
        </div>
    );
};
