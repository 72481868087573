import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Tab } from "../Tabs";
import { campaignUtils } from "../../utils/campaignUtils";
import { arraysContainSameElements } from "../../helpers/array.helper";
import { InterestSelect } from "../selects/InterestSelect";

export const CampaignTagsTab = ({ onUpdate, campaign, ...props }) => {
    const { t } = useTranslation();

    const [interests, setInterests] = useState(campaign.interests || []);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        setIsLoading(true);

        const updatePayload = {
            interests,
        };

        try {
            const updateResponse = await campaignUtils.update(
                campaign?.uuid,
                updatePayload
            );

            if (updateResponse.status === 200) {
                onUpdate(updateResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    const validate = () => {
        if (arraysContainSameElements(interests, campaign?.interests)) {
            return false;
        }

        return true;
    };

    return (
        <Tab {...props}>
            <p className="text-secondary mb-4">
                {t("description.campaign_tags")}
            </p>
            <form onSubmit={handleSubmit}>
                <InterestSelect
                    value={interests}
                    onChange={(v) => setInterests(v)}
                    className="mb-8"
                />
                <div className="text-right">
                    <Button
                        type="submit"
                        variant="primary"
                        isLoading={isLoading}
                        disabled={!validate()}
                    >
                        {t("button.save")}
                    </Button>
                </div>
            </form>
        </Tab>
    );
};
