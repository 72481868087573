import classNames from "classnames";

export const TagLabel = ({ variant, children, className }) => {
    let bgColor = "bg-neutral-500";
    let textColor = "text-neutral-500";

    switch (variant) {
        case "positive":
            bgColor = "bg-green-500";
            textColor = "text-green-500";
            break;
        case "negative":
            bgColor = "bg-red-500";
            textColor = "text-red-500";
            break;
        case "blue":
            bgColor = "bg-blue";
            textColor = "text-blue";
            break;
        case "warning":
            bgColor = "bg-orange-500";
            textColor = "text-orange-500";
            break;
        case "muted":
        default:
            bgColor = "bg-neutral-500";
            textColor = "text-neutral-500";
    }

    return (
        <div className={classNames("flex items-center", className)}>
            <span className={`${bgColor} rounded-full w-2 h-2 mr-2 block`} />
            <span className={`${textColor} font-bold`}>{children}</span>
        </div>
    );
};
