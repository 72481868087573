import { Page } from "../Page";
import { Button } from "../Button";
import { Link, useNavigate } from "react-router-dom";
import { AppHeader } from "../headers/AppHeader";
import { userUtils } from "../../utils/userUtils";
import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { Footer } from "../Footer";

export const HomePage = () => {
    const navigate = useNavigate();
    const [userContext, dispatch] = useContext(UserContext);

    useEffect(() => {
        const jwt = localStorage.getItem("ar-jwt");

        if (jwt && !userContext.uuid) {
            navigate("/play");
        }
    }, []);

    return (
        <Page>
            <AppHeader
                rightContent={
                    <Link to="/login">
                        <Button variant="secondary">Login</Button>
                    </Link>
                }
            />
            <Footer />
        </Page>
    );
};
