import { useContext, useEffect, useRef, useState } from "react";
import { Icon } from "./Icon";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { UserContext } from "./context/UserContext";
import { getEndTextElement } from "./cards/RaffleCard";
import { useTranslation } from "react-i18next";

export const RaffleSpinner = ({ raffle, className, children }) => {
    const { t } = useTranslation();

    const spinnerRef = useRef();

    const [userContext, dispatch] = useContext(UserContext);

    const [userIds, setUserIds] = useState([
        5298, 23576, 12351, 876, 71326, 6536, 12, 2467, 716, 89182, 2122,
    ]);
    const [endText, setEndText] = useState(null);

    const renderEndText = () => {
        const currentDate = new Date();
        const endDate = new Date(raffle?.endAt);

        if (currentDate < endDate) {
            setEndText(
                getEndTextElement(
                    raffle,
                    "!text-xl md:!text-2xl text-shadow",
                    t
                )
            );
        } else {
            setEndText(
                <span className="text-xl text-shadow font-bold">
                    The raffle will end any second
                </span>
            );
        }
    };

    useEffect(() => {
        if (!raffle) {
            return;
        }

        if (!raffle?.idWinner) {
            let endTextInterval;

            renderEndText();

            if (raffle?.endAt) {
                endTextInterval = setInterval(renderEndText, 1000);
            }

            return () => clearInterval(endTextInterval);
        }

        const generatedIds = [];

        for (let i = 0; i < 100; i++) {
            if (i === 82) {
                generatedIds.push(raffle?.idWinner);
            }

            generatedIds.push(randomIntFromInterval(1, 99999));
        }

        setUserIds(generatedIds);

        if (spinnerRef.current) {
            setTimeout(() => {
                const winnerElement = document.getElementById(
                    "winner-spinner-item"
                );

                if (winnerElement) {
                    let transformXValue =
                        winnerElement.offsetLeft -
                        spinnerRef.current?.clientWidth / 2 +
                        randomIntFromInterval(3, 147);

                    spinnerRef.current.style.transform = `translateX(-${transformXValue}px)`;

                    setTimeout(() => {
                        if (spinnerRef.current) {
                            spinnerRef.current.style.transition =
                                "all 250ms linear";

                            let transformXValue =
                                winnerElement.offsetLeft -
                                spinnerRef.current.clientWidth / 2 +
                                75;

                            spinnerRef.current.style.transform = `translateX(-${transformXValue}px)`;

                            const winnerElementTextElement =
                                winnerElement.childNodes?.[0]?.childNodes?.[0];

                            if (winnerElementTextElement) {
                                winnerElementTextElement.style.transition =
                                    "all 250ms linear";

                                winnerElementTextElement.classList.add(
                                    "!text-cash"
                                );
                                winnerElementTextElement.classList.add(
                                    "!text-xl"
                                );
                            }
                        }
                    }, 10000);
                }
            }, [1000]);
        }
    }, [raffle?.idWinner]);

    return (
        <div className={classNames("relative", className)}>
            <div
                className={classNames(
                    "relative transition-all gradient-mask-x",
                    {
                        "blur-sm opacity-60": !raffle?.idWinner,
                    }
                )}
            >
                <div className="overflow-hidden bg-white bg-opacity-5 rounded-lg">
                    <div
                        className="block whitespace-nowrap"
                        style={{
                            transition: "all 10s cubic-bezier(.14,.82,0,1)",
                        }}
                        ref={spinnerRef}
                    >
                        {(userIds || []).map((id, index) => (
                            <RaffleSpinnerItem
                                key={`spinner-item-${index}-${id}`}
                                id={
                                    id === raffle?.idWinner
                                        ? "winner-spinner-item"
                                        : undefined
                                }
                            >
                                <span className="font-bold">
                                    {id === userContext.user.id
                                        ? "You"
                                        : `#${id}`}
                                </span>
                            </RaffleSpinnerItem>
                        ))}
                    </div>
                </div>
                <div className="absolute w-full top-0 text-center py-1">
                    <Icon className="!text-cash" icon={faCaretDown} size="lg" />
                </div>
                <div className="absolute w-full bottom-0 text-center py-1">
                    <Icon className="!text-cash" icon={faCaretUp} size="lg" />
                </div>
            </div>
            {!raffle?.idWinner && (
                <div className="absolute left-0 top-0 w-full h-full flex flex-col text-center justify-center items-center">
                    {endText}
                    {!!children && <div className="mt-4">{children}</div>}
                </div>
            )}
        </div>
    );
};

export const RaffleSpinnerItem = ({ id, children }) => {
    return (
        <div className="w-[150px] h-[150px] inline-block py-8" id={id}>
            <div className="flex w-full h-full items-center justify-center border-r border-opacity-10 border-white">
                {children}
            </div>
        </div>
    );
};

export const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
