import { useTranslation } from "react-i18next";
import { Heading } from "../Heading";
import { Page } from "../Page";
import { AppHeader } from "../headers/AppHeader";
import { Button } from "../Button";
import { useNavigate } from "react-router";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Footer } from "../Footer";

export const DataPrivacyPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Page>
            <AppHeader className="!px-0" />
            <div className="flex items-center mb-4">
                <Button
                    className="mr-4"
                    variant="secondary"
                    onClick={() => navigate(-1)}
                    icon={faChevronLeft}
                    size="small"
                >
                    {t("button.back")}
                </Button>
                <Heading title={t("title.data_privacy")} className="!mb-0" />
            </div>
            <div className="text-wrapper mb-8">
                <div data-custom-class="body">
                    <p className="text-tertiary">Last updated May 30, 2024</p>
                    <div>
                        This privacy notice for Vodor UG ("<strong>we</strong>
                        ," "<strong>us</strong>," or "<strong>our</strong>"),
                        describes how and why we might collect, store, use,
                        and/or share ("<strong>process</strong>") your
                        information when you use our services ("
                        <strong>Services</strong>"), such as when you:
                        <div>
                            <div>
                                <div>
                                    <strong>
                                        Questions or concerns?&nbsp;
                                    </strong>
                                    Reading this privacy notice will help you
                                    understand your privacy rights and choices.
                                    If you do not agree with our policies and
                                    practices, please do not use our Services.
                                </div>
                                <h2>SUMMARY OF KEY POINTS</h2>
                                <div>
                                    <strong>
                                        <em>
                                            This summary provides key points
                                            from our privacy notice, but you can
                                            find out more details about any of
                                            these topics by clicking the link
                                            following each key point or by using
                                            our&nbsp;
                                        </em>
                                    </strong>
                                    <a href="#toc" data-custom-class="link">
                                        <strong>
                                            <em>table of contents</em>
                                        </strong>
                                    </a>
                                    <strong>
                                        <em>
                                            &nbsp;below to find the section you
                                            are looking for.
                                        </em>
                                    </strong>
                                </div>
                                <div>
                                    <strong>
                                        What personal information do we process?
                                    </strong>{" "}
                                    When you visit, use, or navigate our
                                    Services, we may process personal
                                    information depending on how you interact
                                    with us and the Services, the choices you
                                    make, and the products and features you use.
                                    Learn more about&nbsp;
                                    <a
                                        href="#personalinfo"
                                        data-custom-class="link"
                                    >
                                        personal information you disclose to us
                                    </a>
                                    .
                                </div>
                                <div>
                                    <strong>
                                        Do we process any sensitive personal
                                        information?
                                    </strong>{" "}
                                    We do not process sensitive personal
                                    information.
                                </div>
                                <div>
                                    <strong>
                                        Do we collect any information from third
                                        parties?
                                    </strong>{" "}
                                    We may collect information from public
                                    databases, marketing partners, social media
                                    platforms, and other outside sources. Learn
                                    more about&nbsp;
                                    <a
                                        href="#othersources"
                                        data-custom-class="link"
                                    >
                                        information collected from other sources
                                    </a>
                                    .
                                </div>
                                <div>
                                    <strong>
                                        How do we process your information?
                                    </strong>{" "}
                                    We process your information to provide,
                                    improve, and administer our Services,
                                    communicate with you, for security and fraud
                                    prevention, and to comply with law. We may
                                    also process your information for other
                                    purposes with your consent. We process your
                                    information only when we have a valid legal
                                    reason to do so. Learn more about&nbsp;
                                    <a href="#infouse" data-custom-class="link">
                                        how we process your information
                                    </a>
                                    .
                                </div>
                                <div>
                                    <strong>
                                        In what situations and with which
                                        parties do we share personal
                                        information?
                                    </strong>{" "}
                                    We may share information in specific
                                    situations and with specific third parties.
                                    Learn more about&nbsp;
                                    <a
                                        href="#whoshare"
                                        data-custom-class="link"
                                    >
                                        when and with whom we share your
                                        personal information
                                    </a>
                                    .
                                </div>
                                <div>
                                    <strong>What are your rights?</strong>{" "}
                                    Depending on where you are located
                                    geographically, the applicable privacy law
                                    may mean you have certain rights regarding
                                    your personal information. Learn more
                                    about&nbsp;
                                    <a
                                        href="#privacyrights"
                                        data-custom-class="link"
                                    >
                                        your privacy rights
                                    </a>
                                    .
                                </div>
                                <div>
                                    <strong>
                                        How do you exercise your rights?
                                    </strong>{" "}
                                    The easiest way to exercise your rights is
                                    by submitting a&nbsp;
                                    <a
                                        href="https://app.termly.io/notify/c30eb60b-c717-4d1f-a800-51bc8ec7bd9a"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-custom-class="link"
                                    >
                                        data subject access request
                                    </a>
                                    , or by contacting us. We will consider and
                                    act upon any request in accordance with
                                    applicable data protection laws.
                                </div>
                                <div>
                                    Want to learn more about what we do with any
                                    information we collect?&nbsp;
                                    <a href="#toc" data-custom-class="link">
                                        Review the privacy notice in full
                                    </a>
                                    .
                                </div>
                                <h2>TABLE OF CONTENTS</h2>
                                <div>
                                    <a
                                        href="#infocollect"
                                        data-custom-class="link"
                                    >
                                        1. WHAT INFORMATION DO WE COLLECT?
                                    </a>
                                </div>
                                <div>
                                    <a href="#infouse" data-custom-class="link">
                                        2. HOW DO WE PROCESS YOUR INFORMATION?
                                    </a>
                                </div>
                                <div>
                                    <a
                                        href="#whoshare"
                                        data-custom-class="link"
                                    >
                                        3. WHEN AND WITH WHOM DO WE SHARE YOUR
                                        PERSONAL INFORMATION?
                                    </a>
                                </div>
                                <div>
                                    <a href="#cookies" data-custom-class="link">
                                        4. DO WE USE COOKIES AND OTHER TRACKING
                                        TECHNOLOGIES?
                                    </a>
                                </div>
                                <div>
                                    <a
                                        href="#sociallogins"
                                        data-custom-class="link"
                                    >
                                        5. HOW DO WE HANDLE YOUR SOCIAL
                                        LOGINS?&nbsp;&nbsp;
                                    </a>
                                </div>
                                <div>
                                    <a
                                        href="#intltransfers"
                                        data-custom-class="link"
                                    >
                                        6. IS YOUR INFORMATION TRANSFERRED
                                        INTERNATIONALLY?
                                    </a>
                                </div>
                                <div>
                                    <a
                                        href="#inforetain"
                                        data-custom-class="link"
                                    >
                                        7. HOW LONG DO WE KEEP YOUR INFORMATION?
                                    </a>
                                </div>
                                <div>
                                    <a
                                        href="#infominors"
                                        data-custom-class="link"
                                    >
                                        8. DO WE COLLECT INFORMATION FROM
                                        MINORS?
                                    </a>
                                </div>
                                <div>
                                    <a
                                        href="#privacyrights"
                                        data-custom-class="link"
                                    >
                                        9. WHAT ARE YOUR PRIVACY RIGHTS?
                                    </a>
                                </div>
                                <div>
                                    <a href="#DNT" data-custom-class="link">
                                        10. CONTROLS FOR DO-NOT-TRACK FEATURES
                                    </a>
                                </div>
                                <div>
                                    <a
                                        href="#policyupdates"
                                        data-custom-class="link"
                                    >
                                        11. DO WE MAKE UPDATES TO THIS NOTICE?
                                    </a>
                                </div>
                                <div>
                                    <a href="#contact" data-custom-class="link">
                                        12. HOW CAN YOU CONTACT US ABOUT THIS
                                        NOTICE?
                                    </a>
                                </div>
                                <div>
                                    <a href="#request" data-custom-class="link">
                                        13. HOW CAN YOU REVIEW, UPDATE, OR
                                        DELETE THE DATA WE COLLECT FROM YOU?
                                    </a>
                                </div>
                                <h2 id="infocollect">
                                    1. WHAT INFORMATION DO WE COLLECT?
                                </h2>
                                <div>
                                    <strong>
                                        Personal information you disclose to us
                                    </strong>
                                </div>
                                <div>
                                    <div>
                                        <strong>
                                            <em>In Short:</em>
                                        </strong>
                                        <strong></strong>
                                        <em>
                                            We collect personal information that
                                            you provide to us.
                                        </em>
                                    </div>
                                </div>
                                <div>
                                    We collect personal information that you
                                    voluntarily provide to us when you register
                                    on the Services,&nbsp;express an interest in
                                    obtaining information about us or our
                                    products and Services, when you participate
                                    in activities on the Services, or otherwise
                                    when you contact us.
                                </div>
                                <div>
                                    <strong>Sensitive Information.</strong> We
                                    do not process sensitive information.
                                </div>
                                <div>
                                    All personal information that you provide to
                                    us must be true, complete, and accurate, and
                                    you must notify us of any changes to such
                                    personal information.
                                </div>
                                <div>
                                    <strong>
                                        Information automatically collected
                                    </strong>
                                </div>
                                <div>
                                    <div>
                                        <strong>
                                            <em>In Short:</em>
                                        </strong>
                                        <strong></strong>
                                        <em>
                                            Some information &mdash; such as
                                            your Internet Protocol (IP) address
                                            and/or browser and device
                                            characteristics &mdash; is collected
                                            automatically when you visit our
                                            Services.
                                        </em>
                                    </div>
                                </div>
                                <div>
                                    We automatically collect certain information
                                    when you visit, use, or navigate the
                                    Services. This information does not reveal
                                    your specific identity (like your name or
                                    contact information) but may include device
                                    and usage information, such as your IP
                                    address, browser and device characteristics,
                                    operating system, language preferences,
                                    referring URLs, device name, country,
                                    location, information about how and when you
                                    use our Services, and other technical
                                    information. This information is primarily
                                    needed to maintain the security and
                                    operation of our Services, and for our
                                    internal analytics and reporting purposes.
                                </div>
                                <div>
                                    Like many businesses, we also collect
                                    information through cookies and similar
                                    technologies.{" "}
                                </div>
                                <h2 id="infouse">
                                    2. HOW DO WE PROCESS YOUR INFORMATION?
                                </h2>
                                <div>
                                    <div>
                                        <strong>
                                            <em>In Short:&nbsp;</em>
                                        </strong>
                                        <em>
                                            We process your information to
                                            provide, improve, and administer our
                                            Services, communicate with you, for
                                            security and fraud prevention, and
                                            to comply with law. We may also
                                            process your information for other
                                            purposes with your consent.
                                        </em>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <strong>
                                            We process your personal information
                                            for a variety of reasons, depending
                                            on how you interact with our
                                            Services, including:
                                        </strong>
                                    </div>

                                    <div>
                                        <h2 id="whoshare">
                                            3. WHEN AND WITH WHOM DO WE SHARE
                                            YOUR PERSONAL INFORMATION?
                                        </h2>
                                        <div>
                                            <strong>
                                                <em>In Short:</em>
                                            </strong>
                                            <em>
                                                &nbsp;We may share information
                                                in specific situations described
                                                in this section and/or with the
                                                following third parties.
                                            </em>
                                        </div>
                                        <div>
                                            We may need to share your personal
                                            information in the following
                                            situations:
                                        </div>
                                        <ul>
                                            <li data-custom-class="body_text">
                                                <strong>
                                                    Business Transfers.
                                                </strong>{" "}
                                                We may share or transfer your
                                                information in connection with,
                                                or during negotiations of, any
                                                merger, sale of company assets,
                                                financing, or acquisition of all
                                                or a portion of our business to
                                                another company.
                                            </li>
                                        </ul>
                                        <div>
                                            <ul>
                                                <li data-custom-class="body_text">
                                                    <strong>
                                                        Affiliates.&nbsp;
                                                    </strong>
                                                    We may share your
                                                    information with our
                                                    affiliates, in which case we
                                                    will require those
                                                    affiliates to honor this
                                                    privacy notice. Affiliates
                                                    include our parent company
                                                    and any subsidiaries, joint
                                                    venture partners, or other
                                                    companies that we control or
                                                    that are under common
                                                    control with us.
                                                </li>
                                            </ul>
                                            <ul>
                                                <li data-custom-class="body_text">
                                                    <strong>
                                                        Business Partners.
                                                    </strong>{" "}
                                                    We may share your
                                                    information with our
                                                    business partners to offer
                                                    you certain products,
                                                    services, or promotions.
                                                </li>
                                            </ul>
                                            <div>
                                                <div>
                                                    <h2 id="cookies">
                                                        4. DO WE USE COOKIES AND
                                                        OTHER TRACKING
                                                        TECHNOLOGIES?
                                                    </h2>
                                                    <div>
                                                        <strong>
                                                            <em>In Short:</em>
                                                        </strong>
                                                        <em>
                                                            &nbsp;We may use
                                                            cookies and other
                                                            tracking
                                                            technologies to
                                                            collect and store
                                                            your information.
                                                        </em>
                                                    </div>
                                                    <div>
                                                        We may use cookies and
                                                        similar tracking
                                                        technologies (like web
                                                        beacons and pixels) to
                                                        gather information when
                                                        you interact with our
                                                        Services. Some online
                                                        tracking technologies
                                                        help us maintain the
                                                        security of our
                                                        Services, prevent
                                                        crashes, fix bugs, save
                                                        your preferences, and
                                                        assist with basic site
                                                        functions.
                                                    </div>
                                                    <div>
                                                        We also permit third
                                                        parties and service
                                                        providers to use online
                                                        tracking technologies on
                                                        our Services for
                                                        analytics and
                                                        advertising, including
                                                        to help manage and
                                                        display advertisements,
                                                        to tailor advertisements
                                                        to your interests, or to
                                                        send abandoned shopping
                                                        cart reminders
                                                        (depending on your
                                                        communication
                                                        preferences). The third
                                                        parties and service
                                                        providers use their
                                                        technology to provide
                                                        advertising about
                                                        products and services
                                                        tailored to your
                                                        interests which may
                                                        appear either on our
                                                        Services or on other
                                                        websites.
                                                    </div>
                                                    <div>
                                                        Specific information
                                                        about how we use such
                                                        technologies and how you
                                                        can refuse certain
                                                        cookies is set out in
                                                        our Cookie Notice.
                                                    </div>
                                                    <h2 id="sociallogins">
                                                        5. HOW DO WE HANDLE YOUR
                                                        SOCIAL LOGINS?
                                                    </h2>
                                                    <div>
                                                        <strong>
                                                            <em>
                                                                In Short:&nbsp;
                                                            </em>
                                                        </strong>
                                                        <em>
                                                            If you choose to
                                                            register or log in
                                                            to our Services
                                                            using a social media
                                                            account, we may have
                                                            access to certain
                                                            information about
                                                            you.
                                                        </em>
                                                    </div>
                                                    <div>
                                                        Our Services offer you
                                                        the ability to register
                                                        and log in using your
                                                        third-party social media
                                                        account details (like
                                                        your Facebook or X
                                                        logins). Where you
                                                        choose to do this, we
                                                        will receive certain
                                                        profile information
                                                        about you from your
                                                        social media provider.
                                                        The profile information
                                                        we receive may vary
                                                        depending on the social
                                                        media provider
                                                        concerned, but will
                                                        often include your name,
                                                        email address, friends
                                                        list, and profile
                                                        picture, as well as
                                                        other information you
                                                        choose to make public on
                                                        such a social media
                                                        platform.
                                                    </div>
                                                    <div>
                                                        We will use the
                                                        information we receive
                                                        only for the purposes
                                                        that are described in
                                                        this privacy notice or
                                                        that are otherwise made
                                                        clear to you on the
                                                        relevant Services.
                                                        Please note that we do
                                                        not control, and are not
                                                        responsible for, other
                                                        uses of your personal
                                                        information by your
                                                        third-party social media
                                                        provider. We recommend
                                                        that you review their
                                                        privacy notice to
                                                        understand how they
                                                        collect, use, and share
                                                        your personal
                                                        information, and how you
                                                        can set your privacy
                                                        preferences on their
                                                        sites and apps.
                                                    </div>
                                                    <h2 id="intltransfers">
                                                        6. IS YOUR INFORMATION
                                                        TRANSFERRED
                                                        INTERNATIONALLY?
                                                    </h2>
                                                    <div>
                                                        <strong>
                                                            <em>
                                                                In Short:&nbsp;
                                                            </em>
                                                        </strong>
                                                        <em>
                                                            We may transfer,
                                                            store, and process
                                                            your information in
                                                            countries other than
                                                            your own.
                                                        </em>
                                                    </div>
                                                    <div data-custom-class="body_text">
                                                        Our servers are located
                                                        in. If you are accessing
                                                        our Services from
                                                        outside, please be aware
                                                        that your information
                                                        may be transferred to,
                                                        stored, and processed by
                                                        us in our facilities and
                                                        by those third parties
                                                        with whom we may share
                                                        your personal
                                                        information (see "
                                                        <a
                                                            href="#whoshare"
                                                            data-custom-class="link"
                                                        >
                                                            WHEN AND WITH WHOM
                                                            DO WE SHARE YOUR
                                                            PERSONAL
                                                            INFORMATION?
                                                        </a>
                                                        " above), in&nbsp;and
                                                        other countries.
                                                    </div>
                                                    <div>
                                                        If you are a resident in
                                                        the European Economic
                                                        Area (EEA), United
                                                        Kingdom (UK), or
                                                        Switzerland, then these
                                                        countries may not
                                                        necessarily have data
                                                        protection laws or other
                                                        similar laws as
                                                        comprehensive as those
                                                        in your country.
                                                        However, we will take
                                                        all necessary measures
                                                        to protect your personal
                                                        information in
                                                        accordance with this
                                                        privacy notice and
                                                        applicable law.
                                                    </div>
                                                    <h2 id="info-retain">
                                                        7. HOW LONG DO WE KEEP
                                                        YOUR INFORMATION?
                                                    </h2>
                                                    <div>
                                                        <strong>
                                                            <em>
                                                                In Short:&nbsp;
                                                            </em>
                                                        </strong>
                                                        <em>
                                                            We keep your
                                                            information for as
                                                            long as necessary to
                                                            fulfill the purposes
                                                            outlined in this
                                                            privacy notice
                                                            unless otherwise
                                                            required by law.
                                                        </em>
                                                    </div>
                                                    <div>
                                                        We will only keep your
                                                        personal information for
                                                        as long as it is
                                                        necessary for the
                                                        purposes set out in this
                                                        privacy notice, unless a
                                                        longer retention period
                                                        is required or permitted
                                                        by law (such as tax,
                                                        accounting, or other
                                                        legal requirements).
                                                    </div>
                                                    <div>
                                                        When we have no ongoing
                                                        legitimate business need
                                                        to process your personal
                                                        information, we will
                                                        either delete or
                                                        anonymize such
                                                        information, or, if this
                                                        is not possible (for
                                                        example, because your
                                                        personal information has
                                                        been stored in backup
                                                        archives), then we will
                                                        securely store your
                                                        personal information and
                                                        isolate it from any
                                                        further processing until
                                                        deletion is possible.
                                                    </div>
                                                    <h2 id="infominors">
                                                        8. DO WE COLLECT
                                                        INFORMATION FROM MINORS?
                                                    </h2>
                                                    <div>
                                                        <strong>
                                                            <em>In Short:</em>
                                                        </strong>
                                                        <em>
                                                            &nbsp;We do not
                                                            knowingly collect
                                                            data from or market
                                                            to children under 18
                                                            years of age.
                                                        </em>
                                                    </div>
                                                    <div>
                                                        We do not knowingly
                                                        collect, solicit data
                                                        from, or market to
                                                        children under 18 years
                                                        of age, nor do we
                                                        knowingly sell such
                                                        personal information. By
                                                        using the Services, you
                                                        represent that you are
                                                        at least 18 or that you
                                                        are the parent or
                                                        guardian of such a minor
                                                        and consent to such
                                                        minor dependent&rsquo;s
                                                        use of the Services. If
                                                        we learn that personal
                                                        information from users
                                                        less than 18 years of
                                                        age has been collected,
                                                        we will deactivate the
                                                        account and take
                                                        reasonable measures to
                                                        promptly delete such
                                                        data from our records.
                                                        If you become aware of
                                                        any data we may have
                                                        collected from children
                                                        under age 18, please
                                                        contact us at
                                                        support@adroulette.net.
                                                    </div>
                                                    <h2 id="privacy-rights">
                                                        9. WHAT ARE YOUR PRIVACY
                                                        RIGHTS?
                                                    </h2>
                                                    <div>
                                                        <strong>
                                                            <em>In Short:</em>
                                                        </strong>
                                                        <em>
                                                            &nbsp;&nbsp;You may
                                                            review, change, or
                                                            terminate your
                                                            account at any time,
                                                            depending on your
                                                            country, province,
                                                            or state of
                                                            residence.
                                                        </em>
                                                    </div>
                                                    <div>
                                                        <strong>
                                                            <u>
                                                                Withdrawing your
                                                                consent:
                                                            </u>
                                                        </strong>{" "}
                                                        If we are relying on
                                                        your consent to process
                                                        your personal
                                                        information, which may
                                                        be express and/or
                                                        implied consent
                                                        depending on the
                                                        applicable law, you have
                                                        the right to withdraw
                                                        your consent at any
                                                        time. You can withdraw
                                                        your consent at any time
                                                        by contacting us by
                                                        using the contact
                                                        details provided in the
                                                        section "
                                                        <a
                                                            href="#contact"
                                                            data-custom-class="link"
                                                        >
                                                            HOW CAN YOU CONTACT
                                                            US ABOUT THIS
                                                            NOTICE?
                                                        </a>
                                                        " below.
                                                    </div>
                                                    <div>
                                                        However, please note
                                                        that this will not
                                                        affect the lawfulness of
                                                        the processing before
                                                        its withdrawal nor, when
                                                        applicable law allows,
                                                        will it affect the
                                                        processing of your
                                                        personal information
                                                        conducted in reliance on
                                                        lawful processing
                                                        grounds other than
                                                        consent.
                                                    </div>
                                                    <div>
                                                        <strong>
                                                            Account Information
                                                        </strong>
                                                    </div>
                                                    <div>
                                                        If you would at any time
                                                        like to review or change
                                                        the information in your
                                                        account or terminate
                                                        your account, you can:
                                                    </div>
                                                    <div>
                                                        Upon your request to
                                                        terminate your account,
                                                        we will deactivate or
                                                        delete your account and
                                                        information from our
                                                        active databases.
                                                        However, we may retain
                                                        some information in our
                                                        files to prevent fraud,
                                                        troubleshoot problems,
                                                        assist with any
                                                        investigations, enforce
                                                        our legal terms and/or
                                                        comply with applicable
                                                        legal requirements.
                                                    </div>
                                                    <h2 id="DNT">
                                                        10. CONTROLS FOR
                                                        DO-NOT-TRACK FEATURES
                                                    </h2>
                                                    <div>
                                                        Most web browsers and
                                                        some mobile operating
                                                        systems and mobile
                                                        applications include a
                                                        Do-Not-Track ("DNT")
                                                        feature or setting you
                                                        can activate to signal
                                                        your privacy preference
                                                        not to have data about
                                                        your online browsing
                                                        activities monitored and
                                                        collected. At this
                                                        stage, no uniform
                                                        technology standard for
                                                        recognizing and
                                                        implementing DNT signals
                                                        has been finalized. As
                                                        such, we do not
                                                        currently respond to DNT
                                                        browser signals or any
                                                        other mechanism that
                                                        automatically
                                                        communicates your choice
                                                        not to be tracked
                                                        online. If a standard
                                                        for online tracking is
                                                        adopted that we must
                                                        follow in the future, we
                                                        will inform you about
                                                        that practice in a
                                                        revised version of this
                                                        privacy notice.
                                                    </div>
                                                    <h2 id="policyupdates">
                                                        11. DO WE MAKE UPDATES
                                                        TO THIS NOTICE?
                                                    </h2>
                                                    <div>
                                                        <em>
                                                            <strong>
                                                                In Short:&nbsp;
                                                            </strong>
                                                            Yes, we will update
                                                            this notice as
                                                            necessary to stay
                                                            compliant with
                                                            relevant laws.
                                                        </em>
                                                    </div>
                                                    <div>
                                                        We may update this
                                                        privacy notice from time
                                                        to time. The updated
                                                        version will be
                                                        indicated by an updated
                                                        "Revised" date at the
                                                        top of this privacy
                                                        notice. If we make
                                                        material changes to this
                                                        privacy notice, we may
                                                        notify you either by
                                                        prominently posting a
                                                        notice of such changes
                                                        or by directly sending
                                                        you a notification. We
                                                        encourage you to review
                                                        this privacy notice
                                                        frequently to be
                                                        informed of how we are
                                                        protecting your
                                                        information.
                                                    </div>
                                                    <h2 id="contact">
                                                        12. HOW CAN YOU CONTACT
                                                        US ABOUT THIS NOTICE?
                                                    </h2>
                                                    <div>
                                                        If you have questions or
                                                        comments about this
                                                        notice, you may contact
                                                        us by post at:
                                                    </div>
                                                    <div>
                                                        <span>Vodor UG</span>
                                                        <br />
                                                        <span>
                                                            Alte Stuttgarter
                                                            Straße 103
                                                        </span>
                                                        <br />
                                                        <span>
                                                            Stuttgart,
                                                            Baden-Württemberg
                                                            70195
                                                        </span>
                                                        <br />
                                                        <span>Germany</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            Phone: +49 1522
                                                            626969 0
                                                        </span>
                                                        <br />
                                                        <span>
                                                            support@adroulette.net
                                                        </span>
                                                    </div>
                                                    <h2 id="request">
                                                        13. HOW CAN YOU REVIEW,
                                                        UPDATE, OR DELETE THE
                                                        DATA WE COLLECT FROM
                                                        YOU?
                                                    </h2>
                                                    <div>
                                                        Based on the applicable
                                                        laws of your country,
                                                        you may have the right
                                                        to request access to the
                                                        personal information we
                                                        collect from you,
                                                        details about how we
                                                        have processed it,
                                                        correct inaccuracies, or
                                                        delete your personal
                                                        information. You may
                                                        also have the right to
                                                        withdraw your consent to
                                                        our processing of your
                                                        personal information.
                                                        These rights may be
                                                        limited in some
                                                        circumstances by
                                                        applicable law. To
                                                        request to review,
                                                        update, or delete your
                                                        personal information,
                                                        please fill out and
                                                        submit a&nbsp;
                                                        <a
                                                            href="https://app.termly.io/notify/c30eb60b-c717-4d1f-a800-51bc8ec7bd9a"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            data-custom-class="link"
                                                        >
                                                            data subject access
                                                            request
                                                        </a>
                                                        .
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Page>
    );
};
