import classNames from "classnames";

export const Loader = ({ className, duration }) => {
    return (
        <div
            className={classNames(
                "h-40 bg-gradient-to-r from-white/20 via-white/10 to-white/20 rounded-lg animate-loader-pulse",
                className
            )}
            style={{
                animationDuration: duration,
                backgroundSize: "400% 400%",
            }}
        ></div>
    );
};
