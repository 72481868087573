import LogoSvg from "../images/logo.svg";
import LogoWhiteSvg from "../images/logo-white.svg";
import classNames from "classnames";

export const Logo = ({ variant = "white", className }) => {
    let evaluatedLogoSvg = LogoSvg;

    if (variant === "white") {
        evaluatedLogoSvg = LogoWhiteSvg;
    } else {
        evaluatedLogoSvg = LogoSvg;
    }

    return (
        <div className={className}>
            <img
                src={evaluatedLogoSvg}
                alt="adroulette.net"
                className="h-6 mr-2"
            />
        </div>
    );
};
