import { useEffect, useState } from "react";
import { Dialog } from "../Dialog";
import { Textbox } from "../Textbox";
import { Button } from "../Button";
import { LimitMeter } from "../cards/CampaignCard";
import { useTranslation } from "react-i18next";
import { PriceFormatter } from "../../helpers/number.helper";
import { getCPCByDuration } from "../pages/CampaignPage";
import {
    faHandPointer,
    faMousePointer,
} from "@fortawesome/free-solid-svg-icons";

export const LimitDialog = ({
    limit: limitProp = 0,
    campaign,
    onClose,
    onSet,
    ...props
}) => {
    const { t } = useTranslation();

    const [limit, setLimit] = useState(limitProp);

    useEffect(() => {
        init();
    }, [limitProp]);

    const handleClose = () => {
        if (onClose) {
            onClose();

            init();
        }
    };

    const handleSet = () => {
        if (onSet) {
            onSet(limit);
        }

        handleClose();
    };

    const init = () => {
        setLimit(limitProp);
    };

    const validate = () => {
        if (limit < campaign?.watches) {
            return false;
        }

        return true;
    };

    return (
        <Dialog title={t("title.limit")} onClose={handleClose} {...props}>
            <LimitMeter
                limit={limit}
                watches={campaign?.watches || 0}
                className="mb-4"
            />
            <Textbox
                type="number"
                label={t("label.limit")}
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
                className="mb-2"
                error={
                    (limit < campaign?.watches || limit < 0) &&
                    t("validate.limit")
                }
            />
            <div className="grid grid-cols-2 gap-2 mb-4">
                <Button
                    variant="secondary"
                    onClick={() => setLimit(limit - 1000)}
                    disabled={!validate()}
                    icon={faMousePointer}
                >
                    -1000
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => setLimit(limit + 1000)}
                    icon={faMousePointer}
                >
                    +1000
                </Button>
            </div>
            {campaign?.videoDuration && limit > 0 && (
                <p
                    className="text-right mb-4 text-tertiary"
                    dangerouslySetInnerHTML={{
                        __html: t("text.volume", {
                            value: PriceFormatter.format(
                                getCPCByDuration(campaign?.videoDuration) *
                                    (limit - campaign?.watches)
                            ),
                        }),
                    }}
                />
            )}
            <div className="text-right">
                <Button
                    variant="primary"
                    onClick={handleSet}
                    disabled={!validate() || limitProp === limit}
                >
                    {t("button.set")}
                </Button>
            </div>
        </Dialog>
    );
};
