import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { Textbox } from "../Textbox";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Tab } from "../Tabs";
import { userUtils } from "../../utils/userUtils";

export const ChangePasswordTab = ({ onUpdate, ...props }) => {
    const { t } = useTranslation();

    const [userContext] = useContext(UserContext);

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleChangePasswordSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true);

            const updateResponse = await userUtils.update(
                userContext?.user?.uuid,
                { password, newPassword, newPasswordRepeat }
            );

            if (updateResponse.status === 200) {
                if (onUpdate) {
                    onUpdate(updateResponse.data.data);
                }
            }

            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    };

    return (
        <Tab {...props}>
            <form onSubmit={handleChangePasswordSubmit}>
                <Textbox
                    type="password"
                    label={t("label.current_password")}
                    placeholder={t("label.current_password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mb-4"
                />
                <Textbox
                    type="password"
                    label={t("label.new_password")}
                    placeholder={t("label.new_password")}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="mb-4"
                />
                <Textbox
                    type="password"
                    label={t("label.repeat_new_password")}
                    placeholder={t("label.new_password")}
                    value={newPasswordRepeat}
                    onChange={(e) => setNewPasswordRepeat(e.target.value)}
                    className="mb-8"
                />
                <div className="text-right">
                    <Button
                        type="submit"
                        variant="primary"
                        isLoading={isLoading}
                    >
                        {t("button.save")}
                    </Button>
                </div>
            </form>
        </Tab>
    );
};
