export const flashContextReducer = (prevState, action) => {
    switch (action.type) {
        case "SET_FLASH":
            return {
                ...prevState,
                message: action.payload.message,
                variant: action.payload.variant,
            };
        default: {
            return prevState;
        }
    }
};
