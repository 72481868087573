import { useEffect, useState } from "react";
import { campaignUtils } from "../utils/campaignUtils";
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { Card } from "./Card";
import { Button } from "./Button";
import { Icon } from "./Icon";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Loader } from "./Loader";
import { LogoLoader } from "./LogoLoader";

export const CampaignInsights = ({ campaign }) => {
    const { t } = useTranslation();

    const [insights, setInsights] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [range, setRange] = useState("week");

    useEffect(() => {
        fetchInsights();
    }, [campaign?.uuid, range]);

    const fetchInsights = async () => {
        if (!campaign?.uuid) {
            return;
        }

        setIsLoading(true);

        try {
            const insightResponse = await campaignUtils.getInsights(
                campaign?.uuid,
                range
            );

            if (insightResponse.status === 200) {
                setInsights(insightResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    const data = (insights || []).map((v) => ({
        name: new Date(v.date).toLocaleDateString(
            {},
            {
                day: "2-digit",
                month: "2-digit",
            }
        ),
        websiteClicks: v.websiteClicks,
        watches: v.watches,
    }));

    return (
        <Card>
            <div className="flex items-center mb-2">
                <div className="grow">
                    {data?.length > 0 && !isLoading && (
                        <p className="text-secondary">
                            {t("text.watches", {
                                value: insights.reduce(
                                    (prev, curr) => prev + curr.watches,
                                    0
                                ),
                            })}
                        </p>
                    )}
                    {isLoading && <LogoLoader />}
                </div>
                <div>
                    <Button
                        size="small"
                        variant={range === "month" ? "secondary" : "tertiary"}
                        onClick={() => setRange("month")}
                        className="mr-2"
                    >
                        {t("button.month")}
                    </Button>
                    <Button
                        size="small"
                        variant={range === "week" ? "secondary" : "tertiary"}
                        onClick={() => setRange("week")}
                    >
                        {t("button.week")}
                    </Button>
                </div>
            </div>
            <div className="relative w-full h-[300px] rounded">
                {!isLoading && data.length < 1 && (
                    <div className="absolute w-full left-0 top-0 text-center">
                        <span className="block text-lg text-tertiary mt-28 font-semibold">
                            {t("text.no_data_available")}
                        </span>
                    </div>
                )}
                <ResponsiveContainer>
                    <AreaChart
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                        className="relative"
                    >
                        <defs>
                            <linearGradient
                                id="colorWatches"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="0%"
                                    stopColor="#1CF683"
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset="100%"
                                    stopColor="#1CF683"
                                    stopOpacity={0.1}
                                />
                            </linearGradient>
                            <linearGradient
                                id="colorWebsiteClicks"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="0%"
                                    stopColor="#1CA6F6"
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset="100%"
                                    stopColor="#1CA6F6"
                                    stopOpacity={0.1}
                                />
                            </linearGradient>
                        </defs>
                        <Legend align="right" />
                        <CartesianGrid
                            strokeDasharray="3 3"
                            stroke="rgb(37, 37, 37)"
                        />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip wrapperClassName="!bg-card !border-card-border rounded-md" />
                        <Area
                            type="basis"
                            dataKey="watches"
                            name={t("label.watches")}
                            stroke="#1CF683"
                            fill="url(#colorWatches)"
                            fillOpacity={0.4}
                            animationDuration={500}
                            animationEasing="ease-out"
                        />
                        <Area
                            type="basis"
                            dataKey="websiteClicks"
                            name={t("label.website_watches")}
                            stroke="#1CA6F6"
                            fill="url(#colorWebsiteClicks)"
                            fillOpacity={0.4}
                            animationDuration={500}
                            animationEasing="ease-out"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </Card>
    );
};
