import { useNavigate } from "react-router";
import { Button } from "../Button";
import { Dialog } from "../Dialog";

export const LogoutDialog = ({ onClose, ...props }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("ar-jwt");

        navigate("/");
    };

    return (
        <Dialog title="Logout" onClose={onClose} {...props}>
            <p className="mb-4">Are you sure you want to logout?</p>
            <div className="text-right">
                <Button variant="tertiary" className="mr-2" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="destructive" onClick={handleLogout}>
                    Logout
                </Button>
            </div>
        </Dialog>
    );
};
