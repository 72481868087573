import apiHelper from "../helpers/api.helper";

export const raffleUserUtils = {
    getRaffleUser: async (uuid) => {
        return await apiHelper.doApiCall("GET", `/raffleUsers/${uuid}`);
    },
    delete: async (uuid) => {
        return await apiHelper.doApiCall("DELETE", `/raffleUsers/${uuid}`);
    },
    search: async ({ filters, extend }) => {
        return await apiHelper.doApiCall("GET", `/raffleUsers`, {
            params: {
                filters: JSON.stringify(filters || []),
                extend: JSON.stringify(extend || []),
            },
        });
    },
};
