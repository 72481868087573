import classNames from "classnames";

export const Header = ({ title, rightContent, className }) => {
    return (
        <div className={classNames("flex py-4 mb-2 items-center", className)}>
            <div className="grow">
                <h1 className="text-lg font-bold">{title}</h1>
            </div>
            {!!rightContent && <div>{rightContent}</div>}
        </div>
    );
};
