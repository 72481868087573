import { useContext, useEffect, useState } from "react";
import { Page } from "../Page";
import { Card, Field, FieldContainer } from "../Card";
import { UserContext } from "../context/UserContext";
import { CWFATab } from "../tabs/CWFATab";
import { Tabs } from "../Tabs";
import { Textbox } from "../Textbox";
import { Message } from "../Message";
import { transformToLocaleDateString } from "../../helpers/date.helper";
import { PriceFormatter } from "../../helpers/number.helper";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { ChangePasswordTab } from "../tabs/ChangePasswordTab";
import { InterestsTab } from "../tabs/InterestsTab";
import { Link, useNavigate } from "react-router-dom";
import { LogoutDialog } from "../dialogs/LogoutDialog";
import { userUtils } from "../../utils/userUtils";
import { AdvertisementsNavigator } from "../navigators/AdvertisementsNavigator";
import { ClaimedRafflesTab } from "../tabs/ClaimedRafflesTab";
import { LanguageTab } from "../tabs/LanguageTab";
import {
    faAd,
    faArrowRightFromBracket,
    faCheckDouble,
    faHeart,
    faLanguage,
    faLock,
    faMoneyCheck,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { AccountSettingsTab } from "../tabs/AccountSettingsTab";

export const AccountPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [userContext] = useContext(UserContext);

    const [currentTab, setCurrentTab] = useState("change-password");
    const [bankAccount, setBankAccount] = useState(
        userContext?.user?.bankAccount
    );
    const [isLogoutOpen, setIsLogoutOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState(false);

    useEffect(() => {
        setBankAccount(userContext?.user?.bankAccount);
    }, [userContext?.user]);

    const handleBankAccountSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        try {
            setIsLoading(true);

            const updateResponse = await userUtils.update(
                userContext?.user?.uuid,
                {
                    bankAccount,
                }
            );

            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    };

    const validate = () => {
        if (
            (bankAccount?.length < 27 && bankAccount?.length !== 0) ||
            bankAccount === userContext?.user?.bankAccount
        ) {
            return false;
        }

        return true;
    };

    return (
        <Page title={t("title.my_account")}>
            <div className="grid lg:grid-cols-12 gap-4 sm:mb-8">
                <Card wrapperClassName="lg:col-span-4" className="h-full">
                    <form onSubmit={handleBankAccountSubmit}>
                        <div className="mb-2">
                            <h3>
                                {userContext.user.username}{" "}
                                <span className="text-tertiary">
                                    #{userContext?.user?.id}
                                </span>
                            </h3>
                            <span className="text-tertiary">
                                {userContext.user.email}
                            </span>
                        </div>
                        <FieldContainer className="mb-4">
                            <Field label={t("label.tickets")}>
                                {userContext.user.tickets || 0}
                            </Field>
                            <Field label={t("label.registered_since")}>
                                {transformToLocaleDateString(
                                    new Date(userContext?.user?.createdAt)
                                )}
                            </Field>
                        </FieldContainer>
                        <Textbox
                            label={t("label.bank_account")}
                            placeholder={t("placeholder.bank_account")}
                            description={
                                <p
                                    className="text-tertiary"
                                    dangerouslySetInnerHTML={{
                                        __html: t("description.bank_account"),
                                    }}
                                />
                            }
                            value={bankAccount}
                            onChange={(e) => setBankAccount(e.target.value)}
                            charSpaceCount={4}
                            maxLength={27}
                            uppercase
                            size="large"
                            className="mb-8"
                        />
                        <div className="text-right">
                            <Button
                                type="submit"
                                disabled={!validate()}
                                isLoading={isLoading}
                            >
                                {t("button.save")}
                            </Button>
                        </div>
                    </form>
                </Card>
                <Tabs
                    currentTab={currentTab}
                    onTabChange={(v) => setCurrentTab(v)}
                    className="lg:col-span-8"
                >
                    <ChangePasswordTab
                        title={t("title.change_password")}
                        tabId="change-password"
                        icon={faLock}
                    />
                    <LanguageTab
                        title={t("title.language")}
                        tabId="language"
                        icon={faLanguage}
                    />
                    <InterestsTab
                        title={t("title.interests")}
                        tabId="interests"
                        icon={faHeart}
                    />
                    <AccountSettingsTab
                        title={t("title.account_settings")}
                        tabId="account-settings"
                        icon={faUser}
                    />
                    {userContext?.user?.isAdmin && (
                        <CWFATab
                            title={t("title.cwfa")}
                            tabId="cwfa"
                            icon={faCheckDouble}
                        />
                    )}
                    {userContext?.user?.isAdmin && (
                        <ClaimedRafflesTab
                            title={t("title.claimed_raffles")}
                            tabId="claimed-raffles"
                            icon={faMoneyCheck}
                        />
                    )}
                </Tabs>
            </div>
            {!userContext?.user?.advertiser?.uuid && (
                <Link
                    to="/play/account/become-an-advertiser"
                    className="block mb-2"
                >
                    <Button
                        size="large"
                        className="w-full"
                        variant="secondaryYellow"
                        grow
                        rightIcon={faAd}
                    >
                        {t("title.become_an_advertiser")}
                    </Button>
                </Link>
            )}
            {!!userContext?.user?.advertiser?.uuid && (
                <Link to="/play/account/advertisements">
                    <Button
                        variant="secondaryYellow"
                        className="block w-full mb-2"
                        size="large"
                        onClick={() => setIsLogoutOpen(true)}
                        grow
                        rightIcon={faAd}
                    >
                        {t("button.advertisements")}
                    </Button>
                </Link>
            )}
            <Button
                variant="secondaryNegative"
                className="block w-full"
                size="large"
                onClick={() => setIsLogoutOpen(true)}
                grow
                rightIcon={faArrowRightFromBracket}
            >
                {t("button.logout")}
            </Button>
            {!!userContext?.user?.advertiser?.stripe?.paymentActionRequired && (
                <Message
                    variant="warning"
                    className="mb-8"
                    title={t("title.payment_action_required")}
                >
                    {t("text.payment_action_required")}
                </Message>
            )}
            <LogoutDialog
                open={isLogoutOpen}
                onClose={() => setIsLogoutOpen(false)}
            />
        </Page>
    );
};
