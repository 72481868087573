import classNames from "classnames";
import { Glow } from "./Glow";

export const Feature = ({
    children,
    label,
    variant = "primary",
    className,
    glowClassName,
    ...props
}) => {
    return (
        <div
            className={classNames("relative inline-block mx-4", className)}
            {...props}
        >
            {!!glowClassName && <Glow className={glowClassName} />}
            <span
                className={classNames("block", {
                    "text-4xl font-black": variant === "primary",
                    "text-2xl font-bold": variant === "secondary",
                })}
            >
                {children}
            </span>
            <div
                className={classNames({
                    "text-primary":
                        variant === "primary" || variant === "secondary",
                    "font-bold": variant === "primary",
                })}
            >
                {label}
            </div>
        </div>
    );
};
