import { useTranslation } from "react-i18next";
import { DialogFullscreen } from "../DialogFullscreen";

import { useContext, useState } from "react";
import { Textbox } from "../Textbox";
import { Textarea } from "../Textarea";
import { Button } from "../Button";
import { SubPage, SubPages } from "../SubPages";
import { WatchAdDialog } from "./WatchAdDialog";
import { UserContext } from "../context/UserContext";
import { campaignUtils } from "../../utils/campaignUtils";
import { useNavigate } from "react-router";
import { VideoUpload } from "../VideoUpload";
import { InterestSelect } from "../selects/InterestSelect";

export const NewCampaignDialog = ({ onClose, ...props }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [userContext] = useContext(UserContext);

    const [asset, setAsset] = useState(null);
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [description, setDescription] = useState("");
    const [interests, setInterests] = useState([]);
    const [page, setPage] = useState(0);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        init();
        onClose();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        setIsLoading(true);

        try {
            const createResponse = await campaignUtils.create({
                description,
                interests,
                asset,
                websiteUrl,
            });

            if (createResponse.status === 200) {
                navigate(
                    `/play/account/campaigns/${createResponse.data.data.uuid}`
                );
            }

            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    };

    const validate = () => {
        if (!asset || interests.length < 1) {
            return false;
        }

        return true;
    };

    const init = () => {
        setWebsiteUrl("");
        setDescription("");
        setInterests([]);
        setIsPreviewOpen(false);
        setPage(0);
        setAsset(null);
    };

    return (
        <DialogFullscreen
            title={t("title.create_campaign")}
            onClose={handleClose}
            {...props}
        >
            <form onSubmit={handleSubmit}>
                <SubPages currentPage={page}>
                    <SubPage>
                        <div className="grid md:grid-cols-12 gap-8 mb-8">
                            <div className="md:col-span-4">
                                <VideoUpload onUpload={(v) => setAsset(v)} />
                            </div>
                            <div className="md:col-span-8">
                                <h3 className="mb-4">Website</h3>
                                <Textbox
                                    label={t("label.website_url")}
                                    description={t("description.website_url")}
                                    value={websiteUrl}
                                    className="mb-4"
                                    onChange={(e) =>
                                        setWebsiteUrl(e.target.value)
                                    }
                                    maxLength={255}
                                />
                                <Textarea
                                    label={t("label.description")}
                                    description={t("description.description")}
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                    maxLength={240}
                                />
                            </div>
                        </div>
                        <div className="text-right">
                            <Button
                                variant="secondary"
                                className="mr-4"
                                disabled={!asset}
                                onClick={() => setIsPreviewOpen(true)}
                            >
                                {t("button.preview")}
                            </Button>
                            <Button
                                onClick={() => setPage(1)}
                                disabled={!asset}
                            >
                                {t("button.next")}
                            </Button>
                        </div>
                    </SubPage>
                    <SubPage>
                        <InterestSelect
                            value={interests}
                            onChange={(v) => setInterests(v)}
                            label={t("label.tags")}
                            className="mb-8"
                        />
                        <div className="flex justify-end">
                            <Button
                                variant="secondary"
                                className="mr-4"
                                onClick={() => setPage(0)}
                            >
                                {t("button.back")}
                            </Button>
                            <Button
                                type="submit"
                                disabled={!validate()}
                                isLoading={isLoading}
                            >
                                {t("button.create")}
                            </Button>
                        </div>
                    </SubPage>
                </SubPages>
            </form>
            <WatchAdDialog
                open={isPreviewOpen}
                isPreview
                onCancel={() => setIsPreviewOpen(false)}
                onClaim={() => setIsPreviewOpen(false)}
                ad={{
                    campaign: {
                        websiteUrl,
                        description,
                        videoUrl: asset?.secure_url,
                        advertiser: userContext?.user?.advertiser,
                    },
                }}
            />
        </DialogFullscreen>
    );
};

export const getVideoError = (t, video, duration) => {
    if (!t) {
        console.error("No translation function given");

        return null;
    }

    if (!video?.file) {
        return;
    }

    if (video?.file?.size > 20000000) {
        return t("ERROR_VIDEO_TOO_LARGE_DETAIL", {
            value: Math.round(video?.file?.size / 1000000),
        });
    }

    if (video?.file?.type !== "video/mp4") {
        return t("ERROR_WRONG_VIDEO_FORMAT", {
            value: Math.round(video?.file?.type),
        });
    }

    if (duration !== undefined && duration > 30) {
        return t("ERROR_VIDEO_TOO_LONG", {
            value: duration,
        });
    }

    if (duration !== undefined && duration < 5) {
        return t("ERROR_VIDEO_TOO_SHORT", {
            value: duration,
        });
    }

    return null;
};
