import classNames from "classnames";
import { useEffect, useState } from "react";

export const FlashMessage = ({
    children,
    open,
    timeout,
    className,
    onTimeout = () => {},
    variant = "positive",
    ...props
}) => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        let showTimeout;

        if (open) {
            setIsShown(true);

            showTimeout = setTimeout(() => {
                setIsShown(false);
            }, timeout);

            showTimeout = setTimeout(() => {
                onTimeout();
            }, timeout + 200);
        }

        return () => clearTimeout(showTimeout);
    }, [open]);

    if (!open) {
        return false;
    }

    return (
        <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 pointer-events-none z-50">
            <div
                className={classNames(
                    "relative max-w-80 p-4 rounded-md pointer-events-auto transition-all text-center text-2xl uppercase font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]",
                    {
                        "text-green-500": variant === "positive",
                        "text-red-500": variant === "negative",
                        "opacity-0": !isShown,
                        "opacity-100": isShown,
                    },
                    className
                )}
                {...props}
            >
                <div
                    className={classNames(
                        "absolute w-80 h-full blur-3xl left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/4 -z-10 text-",
                        {
                            "bg-green-500/30": variant === "positive",
                            "bg-red-500/30": variant === "negative",
                        }
                    )}
                />
                {children}
            </div>
        </div>
    );
};
