import { useTranslation } from "react-i18next";
import { Heading } from "../Heading";
import { Page } from "../Page";
import { AppHeader } from "../headers/AppHeader";
import { Button } from "../Button";
import { useNavigate } from "react-router";
import { faChevronLeft, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Footer } from "../Footer";
import { Message } from "../Message";
import { useSearchParams } from "react-router-dom";

export const PaymentMethodAddedPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    return (
        <Page>
            <AppHeader className="!px-0" />
            <div className="flex items-center mb-4">
                <Button
                    className="mr-4"
                    variant="secondary"
                    onClick={() =>
                        (window.location.href =
                            searchParams.get("referrer") || "/play")
                    }
                    icon={faChevronLeft}
                    size="small"
                >
                    {t("button.back")}
                </Button>
                <Heading
                    title={t("title.payment_method_added")}
                    className="!mb-0"
                />
            </div>
            <Message icon={faThumbsUp} className="mb-8">
                {t("text.payment_method_added")}
                <div className="text-right mt-4">
                    <Button
                        className="mr-4"
                        variant="secondary"
                        onClick={() =>
                            (window.location.href =
                                searchParams.get("referrer") || "/play")
                        }
                        icon={faChevronLeft}
                        size="small"
                    >
                        {t("button.back")}
                    </Button>
                </div>
            </Message>
            <Footer />
        </Page>
    );
};
