import classNames from "classnames";
import { Card, Field, FieldContainer } from "../Card";
import { TagLabel } from "../TagLabel";
import { useTranslation } from "react-i18next";

export const CampaignCard = ({ campaign, ...props }) => {
    const { t } = useTranslation();

    if (!campaign) {
        return;
    }

    return (
        <Card {...props}>
            <div className="grid grid-cols-1 sm:grid-cols-5 gap-4">
                {campaign?.videoUrl && (
                    <video
                        src={campaign.videoUrl}
                        className="w-full rounded-lg sm:col-span-2"
                        controls
                        poster={campaign.videoUrl.replace(".mp4", ".jpg")}
                    />
                )}
                <div className="sm:col-span-3 space-y-2">
                    <FieldContainer>
                        <Field label={t("label.status")}>
                            <CampaignStatusBadge campaign={campaign} />
                        </Field>
                        {campaign.watches > 0 && (
                            <Field label={t("label.watches")}>
                                {campaign.watches || "0"}
                            </Field>
                        )}
                        {campaign.websiteClick > 0 && (
                            <Field label={t("label.website_watches")}>
                                {campaign.websiteClicks}
                            </Field>
                        )}
                    </FieldContainer>
                    <Field label={t("label.limit")}>
                        {!!campaign.limit ? (
                            <LimitMeter
                                limit={campaign.limit}
                                watches={campaign.watches}
                            />
                        ) : (
                            <span className="text-secondary">
                                {t("text.unlimited")}
                            </span>
                        )}
                    </Field>
                </div>
            </div>
        </Card>
    );
};

export const LimitMeter = ({ limit, watches, className }) => {
    const { t } = useTranslation();

    return (
        <div className={classNames("", className)}>
            <meter
                min={0}
                max={limit}
                value={watches}
                className="w-full neutral-meter mb-1"
            />
            <div className="flex">
                <div className="grow">
                    <span className="text-secondary">{watches}</span>
                </div>
                <span className="text-secondary">
                    {t("text.of_watches", { value: limit })}
                </span>
            </div>
        </div>
    );
};

export const CampaignStatusBadge = ({ campaign }) => {
    const { t } = useTranslation();

    let variant = "success";
    let label = "Live";

    switch (campaign?.status) {
        case 0:
            label = t("label.draft");
            variant = "muted";
            break;
        case 1:
            variant = "blue";
            label = t("label.waiting_for_approval");
            break;
        case 2:
            variant = "negative";
            label = t("label.declined");
            break;
        case 3:
            variant = "positive";
            label = t("label.live");
            break;
        case 4:
            variant = "muted";
            label = t("label.paused");
            break;
        case 5:
            variant = "negative";
            label = t("label.payment_failed");
            break;
        default:
            break;
    }

    if (campaign?.limit > 0 && campaign?.watches >= campaign?.limit) {
        variant = "warning";
        label = t("label.limit_reached");
    }

    return <TagLabel variant={variant}>{label}</TagLabel>;
};
