import classNames from "classnames";

export const Circle = ({ children, className, ...props }) => {
    return (
        <div
            className={classNames(
                "inline-flex h-3 w-3 rounded-full items-center justify-center",
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
};
