import { Link } from "react-router-dom";
import { Header } from "../Header";
import { Logo } from "../Logo";

export const AppHeader = ({ ...props }) => {
    return (
        <Header
            title={
                <Link to="/">
                    <Logo primary />
                </Link>
            }
            {...props}
        />
    );
};
