import { useEffect, useState } from "react";
import { campaignUtils } from "../../utils/campaignUtils";
import { CampaignCard } from "../cards/CampaignCard";
import { useNavigate } from "react-router";
import { Loader } from "../Loader";

export const CampaignList = ({
    status,
    uuidAdvertiser,
    sort,
    className,
    sortDirection,
}) => {
    const navigate = useNavigate();

    const [campaigns, setCampaigns] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchCampaigns = async () => {
        setIsLoading(true);

        try {
            const filters = [];

            if (status !== undefined) {
                filters.push({
                    field: "campaign.status",
                    operator: "=",
                    value: status,
                });
            }

            if (uuidAdvertiser !== undefined) {
                filters.push({
                    field: "campaign.uuidAdvertiser",
                    operator: "=",
                    value: uuidAdvertiser,
                });
            }

            const campaignsResponse = await campaignUtils.search({
                filters,
                sort,
                sortDirection,
            });

            if (campaignsResponse?.status === 200) {
                setCampaigns(campaignsResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        fetchCampaigns();
    }, []);

    return (
        <div className={className}>
            {isLoading && (
                <div className="space-y-4">
                    <Loader duration="6.2s" />
                    <Loader duration="6.4s" />
                    <Loader duration="6.6s" />
                </div>
            )}
            {!isLoading && (
                <div className="space-y-4">
                    {(campaigns || []).map((v) => (
                        <CampaignCard
                            campaign={v}
                            onClick={() =>
                                navigate(`/play/account/campaigns/${v.uuid}`)
                            }
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
