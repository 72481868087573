import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export const Icon = ({ className, ...props }) => {
    return (
        <FontAwesomeIcon
            className={classNames("text-white", className)}
            {...props}
        />
    );
};
