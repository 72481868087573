import classNames from "classnames";

export const Heading = ({
    title,
    subtitle,
    rightContent,
    className,
    size = "medium",
    tag = "h4",
    ...props
}) => {
    const TagElement = tag;

    return (
        <div
            className={classNames(
                "grid grid-cols-[1fr_auto] mb-4 items-center",
                className
            )}
            {...props}
        >
            <div className="overflow-hidden">
                {!!title && (
                    <TagElement
                        className={classNames("whitespace-nowrap truncate", {
                            "text-xl": size === "large",
                            "text-lg": size === "medium",
                            "text-base": size === "small",
                        })}
                    >
                        {title}
                    </TagElement>
                )}
                {!!subtitle && (
                    <p className="text-secondary whitespace-nowrap truncate text-sm">
                        {subtitle}
                    </p>
                )}
            </div>
            {rightContent && <div className="ml-4">{rightContent}</div>}
        </div>
    );
};

// :) i'm just here because I had to be deployed that moment
