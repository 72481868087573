import classNames from "classnames";
import { Circle } from "./Circle";

export const Glow = ({ className, ...props }) => {
    return (
        <Circle
            className={classNames(
                "absolute w-8 h-8 blur-3xl left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 bg-opacity-50 -z-10",
                className
            )}
            {...props}
        />
    );
};
