import axios from "axios";

import i18n from "i18next";

const apiHelper = {
    doApiCall: async (method, endpoint, configuration = {}, isV2 = true) => {
        let url = endpoint;

        if (isV2) {
            configuration.headers = {
                ...configuration,
                Authorization: "Bearer " + localStorage.getItem("ar-jwt"),
            };
            url = "/v2" + url;
        }

        url = process.env.REACT_APP_BACKEND_URI + url;

        return await axios
            .request({
                method,
                url,
                timeout: 30000,
                ...configuration,
            })
            .then((response) => {
                dispatchMessageFromResponse(response);

                return response;
            })
            .catch((errorResponse) => {
                const { response } = errorResponse;

                if (errorResponse.code === "ERR_NETWORK") {
                    dispatchMessageFromResponse(errorResponse);

                    return;
                }

                dispatchMessageFromResponse(response);

                if (
                    (response?.status === 403 &&
                        response.data.message ===
                            "ERROR_FORBIDDEN_ORIGIN_COUNTRY") ||
                    response?.status === 401
                ) {
                    localStorage.removeItem("ar-jwt");

                    window.location = "/login";
                }

                return errorResponse;
            });
    },
};

const dispatchMessageFromResponse = (response) => {
    let flashDispatchEvent;

    if (response.code === "ERR_NETWORK") {
        // Rate limit exceeded

        flashDispatchEvent = new CustomEvent("ar-dispatch-flash", {
            detail: {
                variant: "negative",
                message: i18n.t("ERROR_NETWORK"),
            },
        });

        window.dispatchEvent(flashDispatchEvent);

        return;
    }

    if (response?.data?.message) {
        const translatedMessage = i18n.t(response.data.message);

        if (!excludedDispatchMessages.includes(response.data.message)) {
            switch (response.status) {
                case 200:
                case 204:
                    flashDispatchEvent = new CustomEvent("ar-dispatch-flash", {
                        detail: {
                            variant: "positive",
                            message: translatedMessage,
                        },
                    });

                    break;
                case 429:
                    // Rate limit exceeded

                    flashDispatchEvent = new CustomEvent("ar-dispatch-flash", {
                        detail: {
                            variant: "negative",
                            message: i18n.t("ERROR_API_RATE_LIMIT_EXCEEDED"),
                        },
                    });

                    break;
                case 400:
                case 403:
                case 404:
                case 500:
                    flashDispatchEvent = new CustomEvent("ar-dispatch-flash", {
                        detail: {
                            variant: "negative",
                            message: translatedMessage,
                        },
                    });

                    break;
                default:
                    break;
            }

            if (flashDispatchEvent) {
                window.dispatchEvent(flashDispatchEvent);
            }
        }
    }
};

export const excludedDispatchMessages = ["SUCCESS_AUTH"];

export default apiHelper;
