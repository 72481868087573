import { useEffect, useRef } from "react";

export const MouseFollower = ({ width = 80, height = 80 }) => {
    const followerRef = useRef();

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!followerRef.current) {
                return;
            }

            followerRef.current.style.left = e.clientX - width / 2 + "px";
            followerRef.current.style.top = e.clientY - height / 2 + "px";
        };

        const handleMouseEnter = (e) => {
            if (!followerRef.current) {
                return;
            }

            followerRef.current.style.display = "block";
        };

        const handleMouseLeave = (e) => {
            if (!followerRef.current) {
                return;
            }

            followerRef.current.style.display = "none";
        };

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseenter", handleMouseEnter);
        window.addEventListener("mouseleave", handleMouseLeave);
    }, []);

    return (
        <div className="pointer-events-none overflow-hidden h-full w-full fixed -z-10">
            <div
                className="absolute left-0 top-0 bg-white blur-2xl bg-opacity-10 pointer-events-none -z-10"
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                }}
                ref={followerRef}
            />
        </div>
    );
};
