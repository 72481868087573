import { useState } from "react";
import { Page } from "../Page";
import { Textbox } from "../Textbox";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { AppHeader } from "../headers/AppHeader";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Heading } from "../Heading";
import { useNavigate } from "react-router-dom";
import { userUtils } from "../../utils/userUtils";
import { Message } from "../Message";

export const ResetPasswordPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        setIsLoading(true);

        try {
            const requestPasswordResetResponse =
                await userUtils.requestPasswordReset({ email });

            if (requestPasswordResetResponse.status === 200) {
                setSuccess(true);
            }

            setIsLoading(false);
        } catch (err) {
            console.error(err);

            setIsLoading(false);
        }
    };

    const validate = () => {
        if (!email) {
            return false;
        }

        return true;
    };

    return (
        <Page>
            <AppHeader className="!px-0" />
            <div className="flex items-center mb-4">
                <Button
                    className="mr-4"
                    variant="secondary"
                    onClick={() => navigate(-1)}
                    icon={faChevronLeft}
                    size="small"
                >
                    {t("button.back")}
                </Button>
                <Heading title={t("title.reset_password")} className="!mb-0" />
            </div>
            {!success ? (
                <div>
                    <p className="mb-4">{t("text.reset_password")}</p>
                    <form
                        onSubmit={handleSubmit}
                        className="grid grid-cols-[1fr_auto] items-end gap-2"
                    >
                        <Textbox
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            label={t("label.email")}
                        />
                        <Button
                            type="submit"
                            disabled={!validate()}
                            isLoading={isLoading}
                        >
                            {t("button.submit")}
                        </Button>
                    </form>
                </div>
            ) : (
                <div>
                    <Message variant="positive" icon={faCheck}>
                        {t("text.password_reset_mail_sent")}
                    </Message>
                </div>
            )}
        </Page>
    );
};
