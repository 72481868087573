import classNames from "classnames";
import { Button } from "./Button";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { FlashContext } from "./context/FlashContext";

export const Card = ({
    children,
    className,
    wrapperClassName,
    variant = "neutral",
    onClick,
    title,
    progress,
}) => {
    return (
        <div className={wrapperClassName}>
            {!!title && <h4 className="mb-4">{title}</h4>}
            <div
                className={classNames(
                    "relative p-4 border block rounded-lg overflow-hidden",
                    {
                        "sm:hover:border-opacity-20 active:border-opacity-30 sm:hover:bg-opacity-10 active:bg-opacity-15 cursor-pointer transition-all":
                            !!onClick,
                        "bg-white bg-opacity-5 border-white border-opacity-5":
                            variant === "neutral",
                        "bg-red-500 bg-opacity-10 border-white border-opacity-5":
                            variant === "negative",
                        "bg-cash-secondary bg-opacity-10 border-white border-opacity-5":
                            variant === "positive",
                        "bg-yellow bg-opacity-15 border-white border-opacity-5 sm:hover:bg-opacity-20 active:bg-opacity-25 shadow-yellow-highlight":
                            variant === "yellow",
                        "bg-blue bg-opacity-15 border-white border-opacity-5 sm:hover:bg-opacity-20 active:bg-opacity-25":
                            variant === "blue",
                        "bg-purple bg-opacity-15 border-white border-opacity-5 sm:hover:bg-opacity-20 active:bg-opacity-25 shadow-purple-highlight":
                            variant === "purple",
                    },
                    className
                )}
                onClick={onClick}
            >
                <div>{children}</div>
                {progress > 0 && progress < 100 && (
                    <div
                        className="absolute overflow-hidden h-full left-0 top-0 bg-white/5 pointer-events-none"
                        style={{ width: progress + "%" }}
                    />
                )}
            </div>
        </div>
    );
};

export const FieldContainer = ({ children, className }) => {
    return (
        <div
            className={classNames(
                "grid grid-cols-1 xxs:grid-cols-2 gap-4",
                className
            )}
        >
            {children}
        </div>
    );
};

export const Field = ({ label, children, className, copyable }) => {
    const [flashContext, flashDispatch] = useContext(FlashContext);

    const handleCopy = async () => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(children);

            flashDispatch({
                type: "SET_FLASH",
                payload: {
                    message: `${label} copied to clipboard`,
                    variant: "positive",
                },
            });
        }
    };

    return (
        <div className={classNames("grid grid-cols-[1fr_auto]", className)}>
            <div>
                {!!label && (
                    <span className="block font-semibold">{label}</span>
                )}
                <span className="text-secondary">{children}</span>
            </div>
            {copyable && (
                <Button
                    className="self-start"
                    variant="tertiary"
                    icon={faCopy}
                    onClick={handleCopy}
                />
            )}
        </div>
    );
};
