import classNames from "classnames";
import { Icon } from "./Icon";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { LogoLoader } from "./LogoLoader";
import { CircleProgress } from "./CircleProgress";

export const Button = ({
    className,
    children,
    onClick,
    type = "button",
    variant = "primary",
    disabled,
    isLoading,
    icon,
    rightIcon,
    size = "medium",
    subtitle,
    progress,
    grow,
    ...props
}) => {
    if (isLoading) {
        disabled = true;
    }

    return (
        <button
            type={type}
            className={classNames(
                "font-semibold transition",
                {
                    "bg-primary sm:hover:bg-cash active:bg-cash":
                        variant === "primary",
                    "bg-yellow sm:hover:bg-white active:bg-white":
                        variant === "yellow",
                    "border-white border-opacity-10 border bg-white bg-opacity-5 text-white sm:hover:border-opacity-20 active:border-opacity-30 sm:hover:bg-opacity-10 active:!bg-opacity-15":
                        variant === "secondary" && !disabled,
                    "border-cash border-opacity-10 border bg-cash bg-opacity-5 text-cash sm:hover:border-opacity-20 active:border-opacity-30 sm:hover:bg-opacity-15 active:bg-opacity-20":
                        variant === "secondaryActive",
                    "border-red-500 border-opacity-10 border bg-red-500 bg-opacity-5 text-red-500 sm:hover:border-opacity-20 active:border-opacity-30 sm:hover:bg-opacity-15 active:bg-opacity-20":
                        variant === "secondaryNegative",
                    "border-yellow border-opacity-10 border bg-yellow bg-opacity-5 text-yellow sm:hover:border-opacity-20 active:border-opacity-30 sm:hover:bg-opacity-15 active:bg-opacity-20":
                        variant === "secondaryYellow",
                    "!text-secondary hover:!text-primary bg-white bg-opacity-0 sm:hover:bg-opacity-5 active:bg-opacity-10":
                        variant === "tertiary",
                    "bg-red-500 text-white bg-opacity-85 sm:hover:bg-opacity-90 active:bg-opacity-100":
                        variant === "destructive",
                    "text-red-500": variant === "tertiaryDestructive",
                    "!bg-white !bg-opacity-5 !text-white !text-opacity-40":
                        disabled,
                    "px-3 py-1 rounded-md": size === "small" && !grow,
                    "px-2 rounded-md": size === "small" && grow,
                    "px-4 py-2 rounded-md": size === "medium" && !grow,
                    "p-3 rounded-md": size === "medium" && grow,
                    "px-8 py-4 rounded-lg !font-bold text-md":
                        size === "large" && !grow,
                    "p-4 rounded-lg !font-bold text-md":
                        size === "large" && grow,
                },
                className
            )}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            <div>
                <div className="flex items-center justify-center space-x-2">
                    {progress > 0 && progress < 100 && (
                        <CircleProgress
                            value={progress}
                            width={18}
                            height={18}
                        />
                    )}
                    {!!isLoading && <LogoLoader />}
                    {!!icon &&
                        !isLoading &&
                        (!progress || progress === 100) && (
                            <Icon
                                icon={icon}
                                className={classNames("py-[5px]", {
                                    "!text-black":
                                        variant === "primary" ||
                                        variant === "yellow",
                                    "!text-secondary": variant === "tertiary",
                                    "!text-yellow":
                                        variant === "secondaryYellow",
                                    "!text-white !text-opacity-40": disabled,
                                })}
                                size={size === "large" ? "md" : "sm"}
                            />
                        )}
                    {!!children && (
                        <span
                            className={classNames("!text-inherit", {
                                "grow text-left": grow,
                            })}
                        >
                            {children}
                        </span>
                    )}
                    {!!rightIcon &&
                        !isLoading &&
                        (!progress || progress === 100) && (
                            <Icon
                                icon={rightIcon}
                                className={classNames("py-[5px]", {
                                    "!text-black":
                                        variant === "primary" ||
                                        variant === "yellow",
                                    "!text-secondary": variant === "tertiary",
                                    "!text-red-500":
                                        variant === "secondaryNegative",
                                    "!text-yellow":
                                        variant === "secondaryYellow",
                                    "!text-white !text-opacity-40": disabled,
                                })}
                                fixedWidth={grow}
                                size={size === "large" ? "md" : "sm"}
                            />
                        )}
                </div>
                {!!subtitle && (
                    <p className="text-inherit !font-semibold text-sm">
                        {subtitle}
                    </p>
                )}
            </div>
        </button>
    );
};
