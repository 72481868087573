import { Routes, Route } from "react-router-dom";
import { HomePage } from "./components/pages/HomePage";
import { LoginPage } from "./components/pages/LoginPage";
import { MouseFollower } from "./components/MouseFollower";
import { Play } from "./Play";
import { Footer } from "./components/Footer";
import { TermsOfServicePage } from "./components/pages/TermsOfServicePage";
import { ImprintPage } from "./components/pages/ImprintPage";
import { DataPrivacyPage } from "./components/pages/DataPrivacyPage";
import { PaymentMethodAddedPage } from "./components/pages/PaymentMethodAddedPage";
import { ResetPasswordPage } from "./components/pages/ResetPasswordPage";

function App() {
    return (
        <div className="relative h-full">
            <div className="absolute overflow-hidden h-[800px] w-full pointer-events-none">
                <div
                    className="absolute -top-[640px] h-[800px] w-[1024px] bg-amber-400/30 left-1/2 -translate-x-1/2"
                    style={{ filter: "blur(300px)" }}
                />
            </div>
            <div className="hidden md:block">
                <MouseFollower />
            </div>
            <div className="lg:pt-8 mb-8">
                <Routes>
                    <Route exact path="/" Component={HomePage} />
                    <Route exact path="/login" Component={LoginPage} />
                    <Route
                        exact
                        path="/reset-password"
                        Component={ResetPasswordPage}
                    />
                    <Route path="/play/*" Component={Play} />
                    <Route
                        path="/terms-of-service"
                        Component={TermsOfServicePage}
                    />
                    <Route path="/imprint" Component={ImprintPage} />
                    <Route
                        path="/payment-method-added"
                        Component={PaymentMethodAddedPage}
                    />
                    <Route path="/data-privacy" Component={DataPrivacyPage} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
