import { faTicket, faUser } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "./Icon";
import { Link } from "react-router-dom";
import { Loader } from "./Loader";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

export const Profile = ({ user, isLoading }) => {
    const ticketsRef = useRef();

    const [isUpdatePlaying, setIsUpdatePlaying] = useState(false);
    const [previousTickets, setPreviousTickets] = useState(undefined);

    useEffect(() => {
        if (
            !!previousTickets &&
            !!user?.tickets &&
            user.tickets !== previousTickets
        ) {
            setIsUpdatePlaying(true);

            setTimeout(() => {
                setPreviousTickets(user?.tickets);

                setIsUpdatePlaying(false);
            }, 1000);
        } else {
            setPreviousTickets(user?.tickets);
        }
    }, [user?.tickets, previousTickets]);

    if (isLoading) {
        return (
            <div className="flex items-center">
                <div className="mr-2 flex items-end flex-col">
                    <Loader className="h-[12px] w-24 mb-1" />
                    <Loader className="h-[12px] w-14" />
                </div>
                <Loader className="w-[34px] h-[34px] !rounded-full" />
            </div>
        );
    }

    return (
        <Link to="/play/account">
            <div className="flex items-center">
                {!!user?.username && (
                    <div className="mr-2 text-right">
                        <p className="text-xs font-bold">
                            {user.username}{" "}
                            <span className="text-tertiary">#{user.id}</span>
                        </p>
                        <div className="flex justify-end">
                            <p
                                className={classNames(
                                    "text-xs transition-all mr-1",
                                    {
                                        "!text-cash": !isUpdatePlaying,
                                        "!text-white": isUpdatePlaying,
                                    }
                                )}
                            >
                                <div
                                    className="inline-block h-3 overflow-hidden"
                                    ref={ticketsRef}
                                >
                                    <span
                                        className={classNames(
                                            "inline-flex flex-col",
                                            {
                                                "-translate-y-4":
                                                    !isUpdatePlaying,
                                                "-translate-y-0 transition-all":
                                                    isUpdatePlaying,
                                            }
                                        )}
                                    >
                                        <span
                                            className={classNames(
                                                "transition-all ml-1",
                                                {
                                                    "!text-cash":
                                                        !isUpdatePlaying,
                                                    "!text-white":
                                                        isUpdatePlaying,
                                                }
                                            )}
                                        >
                                            {user.tickets || "0"}
                                        </span>
                                        <span>{previousTickets || "0"}</span>
                                    </span>
                                </div>
                                <Icon
                                    icon={faTicket}
                                    className={classNames(
                                        "transition-all ml-1",
                                        {
                                            "!text-cash": !isUpdatePlaying,
                                            "!text-white": isUpdatePlaying,
                                        }
                                    )}
                                />
                            </p>
                        </div>
                    </div>
                )}
                <div className="w-[34px] h-[34px] bg-white bg-opacity-10 flex items-center justify-center rounded-full">
                    <Icon icon={faUser} />
                </div>
            </div>
        </Link>
    );
};
