import { createContext, useReducer } from "react";
import { userContextReducer } from "./UserContext.reducer";

const initialState = {
    user: {
        uuid: null,
        id: null,
        username: null,
        email: null,
    },
    language: localStorage.getItem("ar-setting-language") || "en_gb",
    isLoading: false,
};

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
    const [store, dispatch] = useReducer(userContextReducer, initialState);

    return (
        <UserContext.Provider value={[store, dispatch]}>
            {children}
        </UserContext.Provider>
    );
};
