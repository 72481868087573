import classNames from "classnames";
import { VideoInput } from "./VideoInput";
import { useEffect, useRef, useState } from "react";
import { userUtils } from "../utils/userUtils";
import { useTranslation } from "react-i18next";
import { getVideoError } from "./dialogs/NewCampaignDialog";
import { campaignUtils } from "../utils/campaignUtils";
import useQuestion from "../hooks/useQuestion";

export const VideoUpload = ({
    className,
    onUpload,
    maxSize = 20000000,
    publicId,
    value,
    question: questionProp,
    questionTitle,
    questionConfirmLabel = "Yes",
    questionCancelLabel = "Cancel",
    questionVariant = "positive",
    ...props
}) => {
    const { t } = useTranslation();

    const videoRef = useRef();

    const [video, setVideo] = useState(null);
    const [isLoadingSignature, setIsLoadingSignature] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(false);
    const [duration, setDuration] = useState(0);
    const { question, QuestionDialog } = useQuestion();

    useEffect(() => {
        setVideo({ blob: value?.secure_url });
    }, [value]);

    const handleChange = async (newValue) => {
        setVideo(newValue);
    };

    const handleLoadedData = async (e) => {
        const duration = e?.target?.duration;

        setDuration(duration);

        if (
            !video?.file ||
            video?.file?.size > 20000000 ||
            duration > 30 ||
            duration < 5
        ) {
            return;
        }

        if (questionProp) {
            const answer = await question(
                questionTitle,
                questionProp,
                questionConfirmLabel,
                questionCancelLabel,
                questionVariant
            );

            if (!answer) {
                return;
            }
        }

        let signature;

        setIsLoadingSignature(true);

        try {
            const uploadSignatureResponse =
                await campaignUtils.getUploadSignature(publicId);

            if (uploadSignatureResponse.status === 200) {
                signature = uploadSignatureResponse.data.data;
            }
        } catch (err) {
            console.error(err);

            return;
        }

        setIsLoadingSignature(false);

        if (!signature) {
            return;
        }

        const uniqueUploadId = generateUniqueUploadId();
        const chunkSize = 5 * 1024 * 1024;
        const totalChunks = Math.ceil(video.file.size / chunkSize);
        let currentChunk = 0;

        setIsUploading(true);

        const uploadChunk = async (start, end) => {
            const formData = new FormData();
            formData.append("file", video.file.slice(start, end));
            formData.append(
                "cloud_name",
                process.env.REACT_APP_CLOUDINARY_SPACE
            );
            formData.append("upload_preset", "campaign_upload");
            formData.append(
                "api_key",
                process.env.REACT_APP_CLOUDINARY_API_KEY
            );
            formData.append("timestamp", signature.timestamp);
            formData.append("signature", signature.signature);
            formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
            formData.append("folder", "campaigns");

            if (publicId) {
                formData.append("public_id", publicId);
            }

            const contentRange = `bytes ${start}-${end - 1}/${video.file.size}`;

            console.log(
                `Uploading chunk for uniqueUploadId: ${uniqueUploadId}; start: ${start}, end: ${
                    end - 1
                }`
            );

            try {
                const response = await fetch(
                    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_SPACE}/auto/upload`,
                    {
                        method: "POST",
                        body: formData,
                        headers: {
                            "X-Unique-Upload-Id": uniqueUploadId,
                            "Content-Range": contentRange,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error("Chunk upload failed.");
                }

                currentChunk++;

                if (currentChunk < totalChunks) {
                    const nextStart = currentChunk * chunkSize;
                    const nextEnd = Math.min(
                        nextStart + chunkSize,
                        video.file.size
                    );
                    uploadChunk(nextStart, nextEnd);

                    setUploadProgress(end / video.file.size);
                } else {
                    setUploadComplete(true);
                    setIsUploading(false);
                    setUploadProgress(0);

                    const fetchResponse = await response.json();

                    onUpload(fetchResponse);

                    console.info("File upload complete.");
                }
            } catch (error) {
                console.error("Error uploading chunk:", error);
                setIsUploading(false);
                setUploadProgress(0);
            }
        };

        const start = 0;
        const end = Math.min(chunkSize, video.file.size);
        uploadChunk(start, end);
    };

    return (
        <div className={className} {...props}>
            <VideoInput
                ref={videoRef}
                value={video}
                onChange={handleChange}
                error={!isUploading && getVideoError(t, video, duration)}
                onLoadedData={handleLoadedData}
                isLoading={isUploading}
            />
            {isUploading && (
                <div className="flex items-center mt-2">
                    <span className="mr-2 animate-pulse text-secondary">
                        {t("text.uploading")}
                    </span>
                    <meter
                        className="neutral-meter w-full block"
                        min={0}
                        max={1}
                        value={uploadProgress}
                    />
                </div>
            )}
            <QuestionDialog />
        </div>
    );
};

const generateUniqueUploadId = () => {
    return `uqid-${Date.now()}`;
};
