import { Route, Routes, useNavigate } from "react-router";
import { PlayPage } from "./components/pages/PlayPage";
import { Header } from "./components/Header";
import { UserContext } from "./components/context/UserContext";
import { useContext, useEffect } from "react";
import { userUtils } from "./utils/userUtils";
import { RafflePage } from "./components/pages/RafflePage";
import { Profile } from "./components/Profile";
import { Button } from "./components/Button";
import { Link } from "react-router-dom";
import { CampaignPage } from "./components/pages/CampaignPage";
import { AccountPage } from "./components/pages/AccountPage";
import { Logo } from "./components/Logo";
import { useTranslation } from "react-i18next";
import { Footer } from "./components/Footer";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./lib/stripe";
import { BecomeAdvertiserPage } from "./components/pages/BecomeAdvertiserPage";
import { AdvertisementsPage } from "./components/pages/AdvertisementsPage";

export const Play = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [userContext, dispatch] = useContext(UserContext);

    const auth = async () => {
        const jwt = localStorage.getItem("ar-jwt");

        if (jwt && !userContext.uuid) {
            try {
                dispatch({ type: "SET_LOADING", payload: true });

                const authResponse = await userUtils.auth();

                if (authResponse.status === 200) {
                    dispatch({
                        type: "SET_USER",
                        payload: authResponse.data.data.user,
                    });

                    dispatch({ type: "SET_LOADING", payload: false });

                    return;
                }
            } catch (err) {
                console.error(err);

                dispatch({ type: "SET_LOADING", payload: false });

                return;
            }
        }

        dispatch({ type: "SET_LOADING", payload: false });

        navigate("/");
    };

    useEffect(() => {
        auth();
    }, []);

    const options = {
        // passing the SetupIntent's client secret
        clientSecret:
            userContext?.user?.advertiser?.stripe?.setupIntentClientSecret,
        // Fully customizable with appearance API.
        appearance: {
            theme: "night",
            labels: "floating",
        },
    };

    return (
        <div className="max-w-screen-lg mx-auto">
            <Header
                title={
                    <Link to="/play" className="align-middle">
                        <Logo primary />
                    </Link>
                }
                className="!p-4"
                rightContent={
                    <div className="flex items-center">
                        {!userContext?.isLoading && (
                            <div className="hidden sm:block">
                                {!userContext?.user?.advertiser ? (
                                    <Link to="/play/account/become-an-advertiser">
                                        <Button
                                            variant="secondaryYellow"
                                            className="!px-3 !py-1 mr-4"
                                        >
                                            {t("title.become_an_advertiser")}
                                        </Button>
                                    </Link>
                                ) : (
                                    <Link to="/play/account/advertisements">
                                        <Button
                                            variant="secondaryYellow"
                                            className="!px-3 !py-1 mr-4"
                                            size="small"
                                        >
                                            {t("button.advertisements")}
                                        </Button>
                                    </Link>
                                )}
                            </div>
                        )}
                        <Profile
                            user={userContext?.user}
                            isLoading={userContext?.isLoading}
                        />
                    </div>
                }
            />
            <Elements stripe={stripePromise} options={options}>
                <Routes>
                    <Route exact path="/" Component={PlayPage} />
                    <Route exact path="/account" Component={AccountPage} />
                    <Route path="/raffle/:uuid" Component={RafflePage} />
                    <Route
                        path="/account/campaigns/:uuid"
                        Component={CampaignPage}
                    />
                    <Route
                        path="/account/become-an-advertiser"
                        Component={BecomeAdvertiserPage}
                    />
                    <Route
                        path="/account/advertisements"
                        Component={AdvertisementsPage}
                    />
                </Routes>
            </Elements>
            <Footer className="px-4 mt-8" />
        </div>
    );
};
