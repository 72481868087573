import { useEffect, useState } from "react";
import { raffleUtils } from "../../utils/raffleUtils";
import { CampaignCard } from "../cards/CampaignCard";
import { useNavigate } from "react-router";
import { Loader } from "../Loader";
import { RaffleCard } from "../cards/RaffleCard";

export const RaffleList = ({
    onlyOngoing,
    onlyUnclaimedWins,
    uuidWinner,
    onDataReceive,
    className,
}) => {
    const navigate = useNavigate();

    const [raffles, setRaffles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchRaffles = async () => {
        setIsLoading(true);

        try {
            const filters = [];

            if (uuidWinner !== undefined) {
                filters.push({
                    field: "raffle.uuidWinner",
                    operator: "=",
                    value: uuidWinner,
                });
            }

            if (onlyOngoing !== undefined) {
                filters.push({
                    field: "raffle.endAt",
                    operator: ">",
                    value: new Date(),
                });
            }

            if (onlyUnclaimedWins !== undefined) {
                filters.push({
                    field: "raffle.uuidWinner",
                    operator: "!=",
                    value: null,
                });

                filters.push({
                    field: "raffle.payedAt",
                    operator: "=",
                    value: null,
                });

                filters.push({
                    field: "raffle.claimedAt",
                    operator: "!=",
                    value: null,
                });
            }

            const rafflesResponse = await raffleUtils.search({
                filters,
            });

            if (rafflesResponse.status === 200) {
                setRaffles(rafflesResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        fetchRaffles();
    }, [uuidWinner]);

    return (
        <div className={className}>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                {isLoading && [
                    <Loader
                        key="raffle-list-loader-1"
                        duration="6.2s"
                        className="h-20"
                    />,
                    <Loader
                        key="raffle-list-loader-2"
                        duration="6.4s"
                        className="h-20"
                    />,
                    <Loader
                        key="raffle-list-loader-3"
                        duration="6.6s"
                        className="h-20"
                    />,
                    <Loader
                        key="raffle-list-loader-4"
                        duration="6.8s"
                        className="h-20"
                    />,
                ]}
                {!isLoading &&
                    (raffles || []).map((raffle) => (
                        <RaffleCard
                            key={`raffle-${raffle.uuid}`}
                            raffle={raffle}
                            onClick={() =>
                                navigate(`/play/raffle/${raffle.uuid}`)
                            }
                            onDataReceive={onDataReceive}
                        />
                    ))}
            </div>
        </div>
    );
};
