import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./components/context/UserContext";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import { Translations } from "./translations.js";
import { FlashContextProvider } from "./components/context/FlashContext.jsx";
import * as serviceWorker from "./serviceWorker.js";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: Translations,
        lng: localStorage.getItem("ar-setting-language"), // if you're using a language detector, do not define the lng option
        fallbackLng: "en_gb",
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <UserContextProvider>
            <FlashContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </FlashContextProvider>
        </UserContextProvider>
    </React.StrictMode>
);

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
