import { Tab } from "../Tabs";
import { CampaignList } from "../lists/CampaignList";

export const CWFATab = ({ ...props }) => {
    return (
        <Tab {...props}>
            <CampaignList status={1} />
        </Tab>
    );
};
