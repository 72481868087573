export const transformUTCToLocaleDate = (utc) => {
    const utcDate = new Date(utc);
    const offset = utcDate.getTimezoneOffset();

    return new Date(utcDate.getTime() - offset * 60000);
};

export const transformToLocaleString = (date) =>
    date?.toLocaleString([], {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    });

export const transformToYTTString = (t, date) => {
    const today = new Date();
    const tomorrow = new Date(today.getTime() + 86400000);
    const yesterday = new Date(today.getTime() - 86400000);

    if (date.toDateString() === today.toDateString()) {
        return t("textDateToday", { time: transformToLocaleTimeString(date) });
    }

    if (date.toDateString() === yesterday.toDateString()) {
        return t("textDateYesterday", {
            time: transformToLocaleTimeString(date),
        });
    }

    if (date.toDateString() === tomorrow.toDateString()) {
        return t("textDateTomorrow", {
            time: transformToLocaleTimeString(date),
        });
    }

    return transformToLocaleString(date);
};

export const transformToLocaleDateString = (date) =>
    date?.toLocaleString([], {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        weekday: "short",
    });

export const transformToLocaleTimeString = (date) =>
    date?.toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit",
    });

export const toMySQLDateTimeString = (date) =>
    date?.toISOString().slice(0, 19).replace("T", " ");

export const toMySQLDateString = (date) =>
    date?.toISOString().slice(0, 10).replace("T", " ");

export const roundMinutes = (date) => {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0);

    return date;
};

export const dateDiff = (start, end) => {
    // Calculate the time difference in milliseconds
    let timeDiff = end.getTime() - start.getTime();

    // Calculate days, hours, minutes, and seconds
    let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        timeDiff,
    };
};
