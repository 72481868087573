export const arraysContainSameElements = (array1, array2) => {
    // Check if arrays are of different lengths
    if (array1.length !== array2.length) {
        return false;
    }

    // Sort both arrays
    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();

    // Compare sorted arrays
    for (let i = 0; i < sortedArray1.length; i++) {
        if (sortedArray1[i] !== sortedArray2[i]) {
            return false;
        }
    }

    return true;
};
