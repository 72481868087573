import { useTranslation } from "react-i18next";

export const AdVideo = ({ src, ad = {}, onTimeUpdate, ...props }) => {
    const { t } = useTranslation();

    const campaign = ad?.campaign;

    if (!campaign) {
        return;
    }

    return (
        <div className="relative">
            <video
                src={campaign.videoUrl}
                onTimeUpdate={onTimeUpdate}
                autoPlay
                className="w-full mb-4 rounded-lg max-h-[500px]"
                disablePictureInPicture
                {...props}
            />
            <div className="absolute w-full h-full top-0 left-0 p-3 text-xs">
                <span className="px-2 py-1 bg-black/30 rounded-md">
                    {t("label.advertisement")}
                </span>
            </div>
        </div>
    );
};
