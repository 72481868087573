import { createContext, useEffect, useReducer } from "react";
import { flashContextReducer } from "./FlashContext.reducer";
import { FlashMessage } from "../FlashMessage";

const initialState = {
    message: null,
    variant: null,
};

export const FlashContext = createContext();

export const FlashContextProvider = ({ children }) => {
    const [store, dispatch] = useReducer(flashContextReducer, initialState);

    useEffect(() => {
        const handleFlashDispatchEvent = (e) => {
            if (e?.detail) {
                dispatch({
                    type: "SET_FLASH",
                    payload: {
                        variant: e.detail.variant,
                        message: e.detail.message,
                    },
                });
            }
        };

        window.addEventListener("ar-dispatch-flash", handleFlashDispatchEvent);

        return () =>
            window.removeEventListener(
                "ar-dispatch-flash",
                handleFlashDispatchEvent
            );
    });

    return (
        <FlashContext.Provider value={[store, dispatch]}>
            {children}
            <FlashMessage
                open={store.message}
                variant={store.variant}
                timeout={3000}
                onTimeout={() =>
                    dispatch({
                        type: "SET_FLASH",
                        payload: { message: null, variant: null },
                    })
                }
            >
                {store.message}
            </FlashMessage>
        </FlashContext.Provider>
    );
};
