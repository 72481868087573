import { useTranslation } from "react-i18next";
import { Page } from "../Page";
import { BecomeAdvertiserForm } from "../forms/BecomeAdvertiserForm";
import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router";

export const BecomeAdvertiserPage = ({ ...props }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [userContext] = useContext(UserContext);

    useEffect(() => {
        if (!userContext.isLoading && !!userContext?.user?.advertiser?.uuid) {
            navigate("/play/account");
        }
    }, [userContext?.user?.advertiser?.uuid, userContext?.isLoading, navigate]);

    return (
        <Page
            title={t("title.become_an_advertiser")}
            parentPage={"/play/account"}
            parentPageTitle={t("title.my_account")}
            {...props}
        >
            <p className="text-secondary mb-4">
                {t("text.become_an_advertiser")}
            </p>
            <BecomeAdvertiserForm />
        </Page>
    );
};
