import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "./Icon";
import classNames from "classnames";

export const CheckButton = ({ className, onClick, children, value }) => {
    return (
        <div
            className={classNames(
                "flex transition-all bg-white/5 sm:hover:bg-white/10 active:!bg-white/15 first:border-t-0 cursor-pointer p-4 items-center",
                className
            )}
            onClick={onClick}
        >
            <label
                className={classNames("cursor-pointer mr-4 block", {
                    "text-cash": !!value,
                })}
            >
                {children}
            </label>
            {!!value && <Icon icon={faCheck} className="!text-cash" />}
        </div>
    );
};
