import { useContext, useEffect, useState } from "react";
import { Button } from "../Button";
import { DialogFullscreen } from "../DialogFullscreen";
import { LogoLoader } from "../LogoLoader";
import { UserContext } from "../context/UserContext";
import { SubPages, SubPage } from "../SubPages";
import { useTranslation } from "react-i18next";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { Message } from "../Message";
import { raffleUtils } from "../../utils/raffleUtils";

export const VisitWebsiteDialog = ({
    ad = {},
    onCancel,
    onClaim,
    isPreview,
    ...props
}) => {
    const [userContext, dispatch] = useContext(UserContext);
    const { t } = useTranslation();

    const [isWebsiteLoaded, setIsWebsiteLoaded] = useState(false);
    const [isLoadingClaim, setIsLoadingClaim] = useState(false);
    const [timestamps, setTimestamps] = useState([]);
    const [secondsElapsed, setSecondsElapsed] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const campaign = ad?.campaign;
    const advertiser = ad?.advertiser;

    useEffect(() => {
        if (timestamps.length >= campaign?.websiteDuration) {
            return;
        }

        let secondsElapsedInterval;

        if (currentPage === 1) {
            secondsElapsedInterval = setInterval(() => {
                setTimestamps([...timestamps, new Date().getTime()]);

                setSecondsElapsed((prevState) => prevState + 1);
            }, 1000);
        } else {
            setSecondsElapsed(0);
        }

        return () => clearInterval(secondsElapsedInterval);
    }, [currentPage, timestamps, campaign?.websiteDuration]);

    if (!campaign) {
        return null;
    }

    const handleLoadedData = (e) => {
        setIsWebsiteLoaded(true);
    };

    const init = () => {
        setIsWebsiteLoaded(false);
        setIsLoadingClaim(false);
        setTimestamps([]);
        setSecondsElapsed(0);
        setCurrentPage(0);
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        init();
    };

    const validate = () => {
        if (secondsElapsed < campaign?.websiteDuration) {
            return false;
        }

        return true;
    };

    const handleClaim = async () => {
        if (!ad) {
            return;
        }

        if (isPreview) {
            onClaim();

            init();

            return;
        }

        setIsLoadingClaim(true);

        try {
            const claimResponse = await raffleUtils.claimWebsiteWatch(
                ad?.raffle?.uuid,
                {
                    adKey: ad.adKey,
                    timestamps,
                }
            );

            if (claimResponse.status === 200) {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        ...userContext.user,
                        tickets: Number(userContext.user.tickets || 0) + 3,
                    },
                });

                onClaim(claimResponse.data.data);
            }
        } catch (err) {
            console.error(err);

            setIsLoadingClaim(false);

            init();
        }

        setIsLoadingClaim(false);

        init();
    };

    const handleWebsiteError = (e) => {
        console.error(e);

        setIsWebsiteLoaded(true);
    };

    const websiteUrl = campaign?.websiteUrl || advertiser?.websiteUrl;
    const description = campaign?.description || advertiser?.description;

    return (
        <DialogFullscreen
            title={advertiser?.name}
            subtitle={websiteUrl}
            size="big"
            onClose={handleCancel}
            {...props}
        >
            <SubPages
                className="h-full"
                wrapperClassName="h-full"
                currentPage={currentPage}
            >
                <SubPage>
                    <div className="mb-4">
                        {!!description && (
                            <p className="mb-4 text-secondary">{description}</p>
                        )}
                        <p
                            className="whitespace-normal font-bold"
                            dangerouslySetInnerHTML={{
                                __html: t("text.visit_website_question"),
                            }}
                        />
                    </div>
                    <Message variant="neutral" className="mb-4">
                        {t("text.golden_ticket_info")}
                    </Message>
                    <div className="text-right">
                        <Button
                            variant="tertiary"
                            className="mr-4"
                            onClick={handleCancel}
                        >
                            {t("button.no")}
                        </Button>
                        <Button
                            variant="yellow"
                            onClick={() => setCurrentPage(1)}
                            rightIcon={faTicket}
                        >
                            {t("button.yes_claim_ticket")}
                        </Button>
                    </div>
                </SubPage>
                <SubPage className="h-full">
                    <div className="flex flex-col h-full">
                        <div className="relative grow mb-4">
                            <iframe
                                src={websiteUrl}
                                title={websiteUrl}
                                className="w-full h-full rounded-xl"
                                onError={handleWebsiteError}
                                onLoad={handleLoadedData}
                                sandbox="allow-scripts"
                            />
                            {secondsElapsed < campaign?.websiteDuration && (
                                <div className="absolute left-4 top-4 pointer-events-none">
                                    <span className="px-2 py-1 bg-black/30 rounded-md">
                                        {t("text.ad_seconds_left", {
                                            value:
                                                campaign?.websiteDuration -
                                                secondsElapsed,
                                        })}
                                    </span>
                                </div>
                            )}
                            {!isWebsiteLoaded && (
                                <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center pointer-events-none">
                                    <div className="text-center">
                                        <LogoLoader
                                            className="w-8 h-8"
                                            rowClassName="h-2"
                                        />
                                        <p className="text-secondary text-sm">
                                            Loading website...
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex pb-4">
                            <div className="grow">
                                <Button
                                    onClick={handleCancel}
                                    variant="tertiary"
                                >
                                    {t("button.cancel")}
                                </Button>
                            </div>
                            <Button
                                disabled={!validate()}
                                onClick={handleClaim}
                                isLoading={isLoadingClaim}
                                rightIcon={faTicket}
                                progress={
                                    secondsElapsed <
                                        campaign?.websiteDuration &&
                                    Math.ceil(
                                        (secondsElapsed /
                                            campaign?.websiteDuration) *
                                            100
                                    )
                                }
                                variant="yellow"
                            >
                                {t("button.claim_golden_ticket")}
                            </Button>
                        </div>
                    </div>
                </SubPage>
            </SubPages>
        </DialogFullscreen>
    );
};
